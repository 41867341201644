const getDateUTCString = (value = ""): string => {
    if (!value) {
        return "";
    }
    const numberOfValue = Number(value);

    const date = new Date(
        Number.isNaN(numberOfValue) ? value : value.length === 13 ? numberOfValue : numberOfValue * 1000,
    );
    const monthNames = window.locales.month;

    return `${date.getUTCDate()} ${
        monthNames[date.getUTCMonth()]
    } ${date.getUTCFullYear()}${window.locales.yearSymbol} ${date.getUTCHours()}:${
        date.getUTCMinutes() > 9 ? date.getUTCMinutes() : `0${date.getUTCMinutes()}`
    } UTC
  `;
};

export default getDateUTCString;
