import { createSlice } from "@reduxjs/toolkit";

import { initFireShield } from "@constants/fireshield";

const initialState = {
    publickey: "",
    privatekey: "",
    project_type: "",
    traffic_limit_type: "",
    config: {
        files: { cnl: "" },
        server_pool: "",
        private_pools: [],
        private_groups: [],
        free_server_pool: "",
        paid_server_pool: "",
        server_pools: [],
        provide: {
            rules: [],
        },
        default_traffic_limit: "",
        fireshield: initFireShield,
        offload: {
            rules: {},
        },
    },
    disable_optimal_location: "",
    role: "",
    icon: "",
    default_license_id: "",
    description: "",
    current_user_role: {
        name: "",
    },
    licenses: [],
};

const activeProjectSlice = createSlice({
    name: "activeProject",
    initialState,
    reducers: {
        getActiveProject(state, action) {},
        setActiveProject(state, action) {
            return action.payload;
        },
        updateActiveProject(state, action) {},
        editActiveProject(state, action) {},
        resetActiveProject() {
            return initialState;
        },
    },
});

export const { setActiveProject, getActiveProject, updateActiveProject, editActiveProject, resetActiveProject } =
    activeProjectSlice.actions;

export { initialState as initActiveProject };

export default activeProjectSlice.reducer;
