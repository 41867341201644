import axios from "axios";

import { putUrlencodedRequestByFirebaseToken } from "@common/requests/requestsAPI";
import { getFirebaseTokenFromState } from "@common/getFirebaseTokenFromState";
import { ProjectType } from "@screens/Projects/Projects.types";

axios.defaults.baseURL = `/api/${process.env.CURRENT_API}/`;
const editProject = (project: ProjectType) =>
    putUrlencodedRequestByFirebaseToken<{ data: ProjectType }>("portal/project", project);

const uploadIconProject = async ({ publickey, icon }: { publickey: string; icon: File }) => {
    const access_token = await getFirebaseTokenFromState();
    const body = new FormData();
    body.append("icon", icon);
    body.append("publickey", publickey);
    return axios.post("portal/project/icon/upload", body, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
};
export { editProject, uploadIconProject };
