import { IObjectTable, ObjectType } from "@screens/Objects/Objects.types";
import { getAge } from "@common/getAge";
import * as table from "@components/Table/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import React from "react";
import Base64toJSON from "@common/methods/base64toJSON";
import { TableObjectProps } from "@components/Table/types";

type IUserTable = {
    name: string;
    role: string;
    author: string;
    age: TableObjectProps;
    buttons?: TableObjectProps;
    event?: () => void;
};
const accumulateUsers = (
    objects: ObjectType[],
    navigate: (item: ObjectType) => void,
    onDelete: (object: ObjectType) => void,
): IObjectTable[] =>
    objects.map((object: ObjectType) => {
        const {
            metadata: { name, author, updateTime = "" },
            data,
        } = object;

        const { role } = Base64toJSON(data) as { role: string };

        const row: IUserTable = {
            name,
            role,
            author,
            age: {
                sortColumn: Number(updateTime),
                component: getAge(updateTime),
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => navigate(object)}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon onClick={() => onDelete(object)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };

        return row;
    });

const usersHeader = [
    {
        isSort: false,
        heading: window.locales.name,
    },
    {
        isSort: false,
        heading: window.locales.role,
    },
    {
        isSort: false,
        heading: window.locales.author,
    },
    {
        isSort: true,
        heading: window.locales.age,
    },
];

export { accumulateUsers, usersHeader };
