import { AxiosResponse } from "axios";

import { postJSONRequestByFirebaseToken } from "@common/requests/requestByFirebaseToken";
import {
    AuthUserCreateRequest,
    AuthUserDataType,
    AuthUsersType,
    AuthUserType,
} from "@services/Auth/User/Auth.User.types";

export class AuthUserService {
    project = "";

    setProject(project: string) {
        this.project = project;
    }

    reactivate = (data: Pick<AuthUserType, "user_id">): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/user/reactivate", data, { "X-project": this.project });

    cancel = (data: Pick<AuthUserType, "user_id">): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/user/cancel", data, { "X-project": this.project });

    create = (data: AuthUserCreateRequest): Promise<AxiosResponse<Pick<AuthUserType, "user_id" | "username">>> =>
        postJSONRequestByFirebaseToken("/auth/user/create", data, { "X-project": this.project });

    delete = (data: Pick<AuthUserType, "user_id">): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/user/delete", data, { "X-project": this.project });

    disable = (data: Pick<AuthUserType, "user_id">): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/user/disable", data, { "X-project": this.project });

    enable = (data: Pick<AuthUserType, "user_id">): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/user/enable", data, { "X-project": this.project });

    get = (data: { alias: string } | Pick<AuthUserType, "user_id">): Promise<AxiosResponse<AuthUserDataType>> =>
        postJSONRequestByFirebaseToken("/auth/user/get", data, { "X-project": this.project });

    list = (data?: { cursor?: string; limit?: number }): Promise<AxiosResponse<AuthUsersType>> =>
        postJSONRequestByFirebaseToken("/auth/user/list", data, { "X-project": this.project });

    update = (data: Partial<AuthUserType>): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/user/update", data, { "X-project": this.project });

    setPassword = (data: Pick<AuthUserType, "user_id"> & { password: string }): Promise<AxiosResponse> =>
        postJSONRequestByFirebaseToken("/auth/user/set_password", data, { "X-project": this.project });

    getEntitlements = (data: Pick<AuthUserType, "user_id">): Promise<AxiosResponse<Record<string, string>>> =>
        postJSONRequestByFirebaseToken("/auth/user/get_entitlements", data, { "X-project": this.project });
}

const authUserService = new AuthUserService();

export default authUserService;
