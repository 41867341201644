// extracted by mini-css-extract-plugin
var _1 = "style-buttonContainer__Ja0ar";
var _2 = "style-configContainer__oA3xs";
var _3 = "style-content__container__iyz8V";
var _4 = "style-fieldContainer__J3fbb";
var _5 = "style-fieldInputContainer__dL6gl";
var _6 = "style-fieldSelect__STh2B";
var _7 = "style-fieldsContainer__R3Cy4";
var _8 = "style-flexTd__bA8T0";
var _9 = "style-form__DFXGS";
var _a = "style-generalContainer__joKNI";
var _b = "style-iconContainer__ociDb";
var _c = "style-iconFile__UbrUW";
var _d = "style-label__RBEvE";
var _e = "style-nameField__sFw92";
var _f = "style-nameFieldContainer__KFp4i";
var _10 = "style-selectWrapper__JKyby";
var _11 = "style-settingsContainer__ULGzw";
var _12 = "style-singleButtonContainer__Kx3QI";
var _13 = "style-tabContainer__WRm4V";
var _14 = "style-tableButton__SfV9J";
var _15 = "style-textContainer__dKoUN";
var _16 = "style-titlePage__vYoGB";
var _17 = "style-twoFieldContainer__HC2e5";
var _18 = "style-uploadButtonContainer__G5r9D";
export { _1 as "buttonContainer", _2 as "configContainer", _3 as "content__container", _4 as "fieldContainer", _5 as "fieldInputContainer", _6 as "fieldSelect", _7 as "fieldsContainer", _8 as "flexTd", _9 as "form", _a as "generalContainer", _b as "iconContainer", _c as "iconFile", _d as "label", _e as "nameField", _f as "nameFieldContainer", _10 as "selectWrapper", _11 as "settingsContainer", _12 as "singleButtonContainer", _13 as "tabContainer", _14 as "tableButton", _15 as "textContainer", _16 as "titlePage", _17 as "twoFieldContainer", _18 as "uploadButtonContainer" }
