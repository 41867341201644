import * as commonStyle from "@common/style/style.scss";
import { NavLink, Outlet } from "react-router-dom";

function Access() {
    return (
        <div className={commonStyle.mainPageContainer}>
            <div className={commonStyle.underTabsContainer}>
                <NavLink
                    to="roles"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.rolesPage}
                </NavLink>
                <NavLink
                    to="users"
                    className={({ isActive }) => `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`}
                >
                    {window.locales.users}
                </NavLink>
            </div>
            <Outlet />
        </div>
    );
}

export default Access;
