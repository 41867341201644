import { ObjectType } from "@screens/Objects/Objects.types";
import * as table from "@components/Table/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import React from "react";
import Base64toJSON from "@common/methods/base64toJSON";
import { AccessDataRoleType } from "@screens/Access/Roles/Roles";
import { getSelectorElement, selectorElement } from "@screens/Network/Pool/Servers/utils";
import { TableObjectProps } from "@components/Table/types";

type IRoleTable = {
    name: string;
    allow_paths: TableObjectProps;
    allow: TableObjectProps;
    buttons?: TableObjectProps;
    event?: () => void;
};

const roleHeader = [
    {
        isSort: false,
        heading: window.locales.name,
    },
    {
        isSort: false,
        heading: window.locales.allow,
    },
    {
        isSort: false,
        heading: window.locales.allow_paths,
    },
];

const accumulateRoles = (
    objects: ObjectType[],
    navigate: (item: ObjectType) => void,
    onDelete: (object: ObjectType) => void,
): IRoleTable[] =>
    objects.map((object: ObjectType) => {
        const {
            metadata: { name },
            data,
        } = object;

        const { allow_paths = [], allow = {} } = Base64toJSON(data) as AccessDataRoleType;
        const row: IRoleTable = {
            name,
            allow: {
                component: getSelectorElement(allow),
            },
            allow_paths: {
                component: (
                    <div className={table.tableTagContainer}>
                        {allow_paths.map((text, key) => (
                            <div className={table.tableTagCountries} key={key}>
                                {text}
                            </div>
                        ))}
                    </div>
                ),
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <TargetIcon onClick={() => navigate(object)}>
                            <img src={iconPen} alt="img" />
                        </TargetIcon>
                        <TargetIcon onClick={() => onDelete(object)}>
                            <img src={iconTrash} alt="img" />
                        </TargetIcon>
                    </div>
                ),
            },
        };

        return row;
    });

export { accumulateRoles, roleHeader };
