import React, { ReactElement } from "react";
import { Field, Formik } from "formik";

import { Modal } from "@components/Modal";
import { CustomSelect, TextField } from "@components/FormikFields";
import { AccessUserType } from "@screens/Access/Users/Users";
import { ModalType } from "@components/Modal/types";

function UserModal({
    title,
    isOpen,
    closeModal,
    action,
    item,
    roles,
}: ModalType<AccessUserType> & { roles: { value: string; label: string }[] }): ReactElement {
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            title={title}
            confirm={{
                label: window.locales.confirm,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="tokenForm"
            disabled={false}
        >
            <Formik
                initialValues={item}
                validate={(values) => {
                    const errors: Record<string, string> = {};
                    const { name, role } = values;
                    if (!name) errors.name = window.locales.invalidFieldEmpty;
                    if (!role) errors.role = window.locales.invalidFieldEmpty;
                    return errors;
                }}
                onSubmit={(user) => {
                    action(user);
                    closeModal();
                }}
            >
                {({ handleSubmit }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="tokenForm" onSubmit={handleSubmit}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type name"
                                type="text"
                                name="name"
                                disabled={item.name}
                            />
                        </section>
                        <section>
                            <Field component={TextField} placeholder="Type token" type="text" name="token" />
                        </section>
                        <section>
                            <Field name="role" options={roles} component={CustomSelect} placeholder="Select role" />
                        </section>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

export default UserModal;
