import React, { useState } from "react";
import { Field, Formik } from "formik";
import { FormikErrors } from "formik/dist/types";

import { signIn } from "@services/firebase";
import { CustomInput } from "@screens/Landing/Authorization/Input";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { errorInfo } from "@state/error";
import { requestCheckProvider } from "@services/Authorization";
import { deleteTokenInStorage } from "@common/tokenStorage";
import { validationEmail } from "@common/methods/checkEmail";

type TSignInWithPassword = {
    email: string;
    goToForgotPwd: () => void;
    isMfaError: (error: any) => boolean;
};

const SignInWithPassword = ({ goToForgotPwd, isMfaError, email }: TSignInWithPassword) => {
    const [isError, setIsError] = useState(false);
    const [isProcess, setIsProcess] = useState(false);

    const goToSignIn = async (email: string, password: string, setErrors: (errors: FormikErrors<any>) => void) => {
        setIsProcess(true);

        try {
            const {
                data: { redirect_url },
            } = await requestCheckProvider(email, window.location.origin);
            if (redirect_url) {
                window.location.replace(redirect_url);
            }
            deleteTokenInStorage();
            await signIn(email.toLowerCase().trim(), password);
        } catch (error: any) {
            if (!isMfaError(error)) {
                errorInfo.setErrorInfo({
                    title: error.code,
                    description: error.message,
                });
                setIsProcess(false);
                setIsError(true);
                setErrors({
                    email: " ",
                    password: " ",
                });
            }
        } finally {
            setIsProcess(false);
        }
    };

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Sign In</div>
                </div>
                <Formik
                    initialValues={{
                        email,
                        password: "",
                    }}
                    validate={(values) => {
                        const errors: any = {};
                        if (!values.email) errors.email = window.locales.invalidEmptyEmail;
                        if (!validationEmail(values.email)) errors.email = window.locales.invalidEmail;
                        if (!values.password) errors.password = window.locales.invalidEmptyPassword;
                        setIsError(false);
                        return errors;
                    }}
                    onSubmit={({ email, password }, { setErrors }) => goToSignIn(email, password, setErrors)}
                >
                    {({ values, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                            <section>
                                <div className={authorizationStyle.fieldContainer}>
                                    <Field
                                        dataTestId="input.email"
                                        data-test-id="input.email"
                                        component={CustomInput}
                                        value={values.email}
                                        type="text"
                                        name="email"
                                        placeholder="Enter your email"
                                        autoComplete="username"
                                    />
                                </div>
                                <div className={authorizationStyle.fieldContainer}>
                                    <Field
                                        dataTestId="input.password"
                                        component={CustomInput}
                                        value={values.password}
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        autoComplete="current-password"
                                    />
                                </div>
                            </section>
                            {isError && (
                                <div className={authorizationStyle.errorContainer}>{window.locales.signInError}</div>
                            )}
                            <div className={authorizationStyle.infoContainer}>
                                <span>Forgot your password?</span>
                                <div className={authorizationStyle.buttonResetPassword} onClick={goToForgotPwd}>
                                    Reset password
                                </div>
                            </div>
                            <div className={authorizationStyle.buttonContainer}>
                                <button
                                    className={buttonStyle.buttonSubmit}
                                    disabled={isError || isProcess}
                                    type="submit"
                                    data-test-id="button.signin"
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SignInWithPassword;
