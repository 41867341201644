import React, { ReactElement, useMemo } from "react";
import { Field, FieldArray, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Editor } from "@monaco-editor/react";
import { useSelector } from "react-redux";

import * as poolStyle from "@screens/Network/Pool/style.scss";
import { CustomSelect, TextAreaField, TextField } from "@components/FormikFields";
import { TargetIcon } from "@components/TargetIcon";
import iconTrash from "@common/img/svg/trash-icon.svg";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus } from "@common/icons";
import { DiscoveryPoolConfig, PermissionType } from "@screens/Pools/DiscoveryPool.types";
import { monacoEditorOptions } from "@common/constant/monacoOption";
import { NameContainer } from "@components/FormikFields/components";
import * as localStyle from "@screens/Pools/Pool/Parameters/ConfigParameters.style.scss";
import RootState from "@state/interfaces";
import { ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import { usePool } from "@screens/Pools/Pool/usePool";
import objectStore from "@state/object";
import { ObjectStoreType } from "@state/object/ObjectStore.types";

const permissionsOptions = [
    ["can_read", "Read"],
    ["can_use", "Use"],
    ["can_write", "Write"],
].map(([value, label]) => ({
    value,
    label,
}));

const defaultPermission = {
    subject: "",
    list: [],
    can_list: false,
    can_read: false,
    can_use: false,
    can_write: false,
};

const PoolConfigData = observer((): ReactElement => {
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const canCreateAndEdit = objectPermissions[ObjectEnum.DISCOVERY_POOL_CONFIG]?.includes(PermissionEnum.PUT);
    const { poolAction, isRequesting, poolData } = usePool(
        objectStore.objectData as ObjectStoreType<DiscoveryPoolConfig>,
    );
    const customPermissions = useMemo<PermissionType[]>(() => {
        if (!poolData.permissions) {
            return [];
        }

        poolData.permissions.forEach((permission) => {
            permission.list = Object.entries(permission)
                .filter(([, value]) => typeof value === "boolean" && value)
                .map(([key]) => key);
        });

        return poolData.permissions;
    }, [poolData]);

    return (
        <Formik
            initialValues={{
                description: poolData.description,
                permissions: customPermissions,
                selector: poolData.selector ? JSON.stringify(poolData.selector, undefined, 4) : "{}",
            }}
            validate={({ permissions }) => {
                const errors: Record<string, string> = {};
                const index = permissions?.findIndex((permission) => !permission.subject);
                if (index !== -1) errors[`permissions.${index}.subject`] = window.locales.emptyField;
                return errors;
            }}
            validateOnChange={false}
            enableReinitialize
            onSubmit={async ({ permissions, selector, description }) => {
                const updatedPermissions = permissions.map((permission) => {
                    const newPermission = {
                        ...permission,
                        can_read: permission.list?.includes("can_read") || false,
                        can_use: permission.list?.includes("can_use") || false,
                        can_write: permission.list?.includes("can_write") || false,
                    };
                    delete newPermission.list;
                    return newPermission;
                });
                const pool: DiscoveryPoolConfig = {
                    permissions: updatedPermissions,
                    description,
                    selector: JSON.parse(selector),
                };

                await poolAction(pool);
            }}
        >
            {({ values, handleSubmit, touched, setFieldValue }) => (
                /* ! add id to match nameForm from the Modal props  */
                <form id="editForm" onSubmit={handleSubmit} className={localStyle.poolConfigData}>
                    <section>
                        <NameContainer $isError={false}>{window.locales.permissions}</NameContainer>
                        <FieldArray
                            name="permissions"
                            render={(arrayHelpers) => (
                                <>
                                    <div className={localStyle.permissionsContainer}>
                                        {values.permissions &&
                                            values.permissions.length > 0 &&
                                            values.permissions.map((permission, i) => (
                                                <div key={i} className={poolStyle.permissionContainer}>
                                                    <Field
                                                        extraTouched={
                                                            touched.permissions?.length && !!touched.permissions[i]
                                                        }
                                                        component={TextField}
                                                        placeholder="Project id"
                                                        type="text"
                                                        name={`permissions.${i}.subject`}
                                                    />
                                                    <Field
                                                        isMulti
                                                        component={CustomSelect}
                                                        options={permissionsOptions}
                                                        name={`permissions.${i}.list`}
                                                        placeholder="Select permission"
                                                    />
                                                    <TargetIcon onClick={() => arrayHelpers.remove(i)}>
                                                        <img src={iconTrash} alt="img" />
                                                    </TargetIcon>
                                                </div>
                                            ))}
                                    </div>
                                    <section className={poolStyle.extraButtons}>
                                        <button
                                            type="button"
                                            onClick={() => arrayHelpers.push(defaultPermission)}
                                            className={buttonStyle.buttonAdd}
                                        >
                                            <IconPlus theme="blue" />
                                            {window.locales.addPermission}
                                        </button>
                                    </section>
                                </>
                            )}
                        />
                    </section>
                    <section>
                        <NameContainer $isError={false}>{window.locales.selector}</NameContainer>
                        <Editor
                            height="20vh"
                            options={monacoEditorOptions}
                            value={values.selector}
                            onChange={(value) => setFieldValue("selector", value)}
                            theme="vs-dark"
                            defaultLanguage="json"
                        />
                    </section>
                    <section>
                        <Field
                            component={TextAreaField}
                            value={values.description}
                            type="text"
                            name="description"
                            placeholder="Type a description"
                        />
                    </section>
                    <div className={buttonStyle.singleButtonContainer}>
                        <button
                            type="submit"
                            disabled={!canCreateAndEdit || isRequesting}
                            className={buttonStyle.buttonSubmit}
                        >
                            {window.locales.save}
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
});

export default PoolConfigData;
