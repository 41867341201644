import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";

import { editActiveProject } from "@state/activeProject";
import { RootState } from "@state/index";
import { ERoles } from "@screens/Settings/enums";
import * as style from "@screens/Settings/style.scss";
import { TextField, CustomSelect } from "@components/FormikFields";
import * as buttonStyle from "@components/Button/style.scss";

const b2Mb = (num: string) => Math.round(Number(num) * 9.537 * 1e-7).toString();
const mb2B = (num: string) => (Number(num) * 1048576).toString();

const optionsOptimalLocations = [
    {
        value: "true",
        label: window.locales.disabled,
    },
    {
        value: "false",
        label: window.locales.enabled,
    },
];

const GeneralVPN = () => {
    const dispatch = useDispatch();
    const activeProject = useSelector((state: RootState) => state.activeProject);

    return (
        <Formik
            initialValues={{
                bandwidth_limit: activeProject.config.default_traffic_limit
                    ? b2Mb(activeProject.config.default_traffic_limit)
                    : "",
                optimal_location: activeProject.disable_optimal_location || "false",
            }}
            enableReinitialize
            validate={({ bandwidth_limit }) => {
                const errors: any = {};
                if (Number.isNaN(Number(bandwidth_limit)))
                    errors.default_bandwidth_limit = window.locales.limitedExceeded;
                return errors;
            }}
            onSubmit={({ bandwidth_limit, optimal_location }) => {
                dispatch(
                    editActiveProject({
                        ...activeProject,
                        config: {
                            ...activeProject.config,
                            default_traffic_limit: mb2B(bandwidth_limit),
                            disable_optimal_location: optimal_location,
                        },
                    }),
                );
            }}
        >
            {({ values, handleSubmit, dirty, isValid }) => (
                <form onSubmit={handleSubmit}>
                    <section>
                        <div className={style.fieldsContainer}>
                            <span>{window.locales.bandwidthType}</span>
                            <div className={style.textContainer}>
                                {window.locales[activeProject.traffic_limit_type]}
                            </div>
                            <Field
                                component={TextField}
                                disabled={activeProject.role !== ERoles.Owner}
                                type="text"
                                name="bandwidth_limit"
                                placeholder="Limit Mb"
                            />
                            <Field
                                component={CustomSelect}
                                disabled={activeProject.role !== ERoles.Owner}
                                value={values.optimal_location}
                                options={optionsOptimalLocations}
                                name="optimal_location"
                                placeholder="Optimal location"
                            />
                        </div>
                    </section>
                    <div className={style.singleButtonContainer}>
                        <button
                            className={buttonStyle.buttonSubmit}
                            type="submit"
                            disabled={activeProject.role === ERoles.Support || !(isValid && dirty)}
                        >
                            {window.locales.save}
                        </button>
                    </div>
                </form>
            )}
        </Formik>
    );
};

export default GeneralVPN;
