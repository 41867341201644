import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as profileStyle from "@screens/Profile/style.scss";
import { pageViewsTracking } from "@common/gaTracking/pageViewsTracking";
import { MultiFactorInfo, multiFactor } from "firebase/auth";
import * as twoFactoryAuthStyle from "@screens/Profile/AccountSecurity/TwoFactoryAuth/style.scss";
import { deleteTokenInStorage } from "@common/tokenStorage";
import { deleteAccount } from "@state/account";
import { useDispatch } from "react-redux";
import { errorInfo } from "@state/error";
import { successInfo } from "@state/success";
import { Modal } from "@components/Modal";

export const DISABLE_MFA_STEP = "DISABLE_MFA_STEP";

export const TwoFactoryAuthForm = () => {
    const dispatch = useDispatch();
    const [multiFactorInfo, setMultiFactorInfo] = useState<MultiFactorInfo>();
    const location = useLocation();
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
    const { currentUser } = window.fb.default.auth();
    useEffect(() => {
        pageViewsTracking(location.pathname);
        if (currentUser) {
            const [info] = currentUser ? currentUser.multiFactor.enrolledFactors : [];
            setMultiFactorInfo(info);
        }
    }, [currentUser, location.pathname, navigate]);

    const unenrollTwoFactoryAuth = useCallback(async () => {
        if (!multiFactorInfo) return;
        setModalOpen(false);
        try {
            await multiFactor(currentUser).unenroll(multiFactorInfo);
            sessionStorage.removeItem(DISABLE_MFA_STEP);
            window.location.reload();
        } catch {
            sessionStorage.setItem(DISABLE_MFA_STEP, "true");
            errorInfo.setErrorInfo({
                description: window.locales.disableMFA,
            });
            deleteTokenInStorage();
            dispatch(deleteAccount());
        }
    }, [multiFactorInfo, dispatch]);

    useEffect(() => {
        const isDisableMFAStep = sessionStorage.getItem(DISABLE_MFA_STEP);
        if (isDisableMFAStep) {
            unenrollTwoFactoryAuth();
        }
    }, [unenrollTwoFactoryAuth]);

    if (!window.fb) {
        return <Navigate replace to="/" />;
    }

    return (
        <div className={profileStyle.titleSection}>
            {window.locales.twoFactory}
            <div className={twoFactoryAuthStyle.resetButton} onClick={() => setModalOpen(true)}>
                {window.locales.disable} {multiFactorInfo?.factorId}
            </div>
            <Modal
                isNegative
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                title={window.locales.disableMFATitle}
                confirm={{
                    label: window.locales.disable,
                    onConfirm: unenrollTwoFactoryAuth,
                }}
            >
                {window.locales.qDisableMFA}
            </Modal>
        </div>
    );
};
