// Function to set the highlight colors dynamically based on the environment
const setHighlightColors = (env: string) => {
    // Default colors for dev environment
    const colors: Record<string, Record<string, string>> = {
        dev: {
            highlightColorDefault: "#e6a42d",
            highlightColorActive: "#c7860f",
            highlightColorHover: "#d39523",
            highlightImgColorDefault:
                "invert(62%) sepia(94%) saturate(398%) hue-rotate(354deg) brightness(96%) contrast(87%)",
            highlightImgColorActive:
                "invert(56%) sepia(24%) saturate(5233%) hue-rotate(13deg) brightness(95%) contrast(88%)",
            highlightImgColorHover:
                "invert(85%) sepia(46%) saturate(3322%) hue-rotate(341deg) brightness(86%) contrast(91%)",
        },
        stage: {
            highlightColorDefault: "#16b634",
            highlightColorActive: "#1f812f",
            highlightColorHover: "#0fa72d",
            highlightImgColorDefault:
                "invert(57%) sepia(74%) saturate(1972%) hue-rotate(86deg) brightness(90%) contrast(87%)",
            highlightImgColorActive:
                "invert(43%) sepia(11%) saturate(2350%) hue-rotate(78deg) brightness(94%) contrast(110%)",
            highlightImgColorHover:
                "invert(47%) sepia(48%) saturate(1102%) hue-rotate(85deg) brightness(93%) contrast(93%)",
        },
        // Add other environments if needed
    };

    const root = document.documentElement; // Access the root element (html)

    // Check if the environment is valid and set the CSS variables accordingly
    if (colors[env]) {
        const selectedColors = colors[env];

        // Set each CSS custom property dynamically
        Object.keys(selectedColors).forEach((key) => {
            root.style.setProperty(`--${key}`, selectedColors[key]);
        });
    } else {
        console.error(`Unknown environment: ${env}`);
    }
};

// Get environment from an environment variable or default to 'dev'
const environment = process.env.CURRENT_API || "dev"; // Replace this with logic to detect environment
setHighlightColors(environment);
