import React from "react";
import { Field, Formik } from "formik";

import { resetPassword } from "@services/firebase";
import { CustomInput } from "@screens/Landing/Authorization/Input";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import { validationEmail } from "@common/methods/checkEmail";

const ForgotCredentials = (props: { setSuccess: (email: string) => void }) => (
    <div className={authorizationStyle.formCenter}>
        <div className={authorizationStyle.signFormContainer}>
            <div className={authorizationStyle.titleContainer}>
                <div className={authorizationStyle.title}>Forgot password</div>
                <div className={authorizationStyle.text}>
                    There is no problem, enter your email and we’ll send you instructions
                </div>
            </div>
            <Formik
                initialValues={{ email: "" }}
                validate={(values) => {
                    const errors: any = {};
                    if (!values.email) errors.name = window.locales.invalidCompanyName;
                    if (!validationEmail(values.email)) errors.email = window.locales.invalidEmail;
                    return errors;
                }}
                onSubmit={(values) => {
                    resetPassword(values.email).finally(() => {
                        props.setSuccess(values.email);
                    });
                }}
            >
                {({ values, handleSubmit }) => (
                    <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                        <div className={authorizationStyle.fieldContainer}>
                            <Field
                                component={CustomInput}
                                value={values.email}
                                type="text"
                                name="email"
                                placeholder="Enter your email"
                            />
                        </div>
                        <div className={authorizationStyle.infoContainer}>
                            <span>Forgot your registration email too?</span>
                            <a href="mailto: partners@aura.com" target="blank">
                                Contact us
                            </a>
                        </div>
                        <div className={authorizationStyle.buttonContainer}>
                            <button className={buttonStyle.buttonSubmit} type="submit">
                                Send
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    </div>
);

export { ForgotCredentials };
