import React from "react";

import { ChangePassword } from "@screens/Profile/AccountSecurity/ChangePassword";
import { TwoFactoryAuthForm } from "@screens/Profile/AccountSecurity/TwoFactoryAuth/TwoFactoryAuthForm";

import * as styles from "./AccountSecurity.style.scss";

const AccountSecurity = () => (
    <div className={styles.accountSecurityContainer}>
        <section>
            <TwoFactoryAuthForm />
        </section>
        <section>
            <ChangePassword />
        </section>
    </div>
);

export default AccountSecurity;
