import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import RootState from "@state/interfaces";
import { ProjectIcon } from "@components/IconProject";
import { ProjectsSelector } from "@components/Header/ProjectsSelector/ProjectSelector";
import * as style from "@components/Header/LeftTopBar/style.scss";
import pangoBigIcon from "@common/img/svg/pango-logo.svg";
import pangoSmallIcon from "@common/img/svg/pango-logo-white.svg";
import * as arrowsStyle from "@components/Arrows/style.scss";
import { If } from "@components/If";
import { CatchOutsideClick } from "@common/methods/catchOutsideClick";
import { resetPartOfStore } from "@state/index";

const LeftTopBar = () => {
    const projectsRef = useRef(null);
    const activeProject = useSelector((state: RootState) => state.activeProject);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpened, setIsOpened] = useState(false);
    CatchOutsideClick(projectsRef, () => {
        setIsOpened(false);
    });

    const goToProjects = () => {
        dispatch(resetPartOfStore());
        navigate("/");
    };

    const handleClickProject = () => {
        setIsOpened(true);
    };

    useEffect(
        () => () => {
            setIsOpened(false);
        },
        [activeProject],
    );

    return (
        <div className={style.logoContainer}>
            <div className={style.mainLogoContainer} onClick={goToProjects}>
                <img src={activeProject.publickey ? pangoSmallIcon : pangoBigIcon} alt="img" />
            </div>
            <If condition={!!activeProject.publickey}>
                <div className={style.projectIconContainer} onClick={handleClickProject} ref={projectsRef}>
                    <div className={style.projectName}>
                        <span>
                            <ProjectIcon icon={activeProject.icon} carrier_name={activeProject.publickey} />
                        </span>
                        {activeProject.publickey}
                    </div>
                    <div className={style.chevronContainer}>
                        <i className={isOpened ? arrowsStyle.arrowUp : arrowsStyle.arrowDown} />
                    </div>
                    <If condition={isOpened}>
                        <ProjectsSelector />
                    </If>
                </div>
            </If>
        </div>
    );
};

export default LeftTopBar;
