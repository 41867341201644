import React, { useState, useEffect, FC, ChangeEvent, KeyboardEvent } from "react";

import { TSearchProps } from "@interfaces";
import * as inputStyle from "@common/style/input.style.scss";
import searchIcon from "@common/img/svg/search-icon.svg";

import * as style from "./style.scss";

const Search: FC<TSearchProps> = ({ callback, enterEvent }) => {
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        if (searchValue !== "") {
            callback(searchValue);
        }
    }, [callback, searchValue]);

    const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== "Enter") {
            return;
        }

        if (searchValue !== "" && enterEvent) {
            enterEvent();
        }
    };

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        callback(e.target.value);
    };

    return (
        <div className={style.projectSearchContainer}>
            <span className={style.searchIconContainer}>
                <img src={searchIcon} alt="img" />
            </span>
            <input
                className={inputStyle.commonInput}
                value={searchValue}
                onKeyDown={onEnter}
                onChange={onChange}
                placeholder={window.locales.search}
            />
        </div>
    );
};

export { Search };
