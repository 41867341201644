import React, { createContext, ReactElement, useCallback, useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { observer } from "mobx-react-lite";

import * as commonStyle from "@common/style/style.scss";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import { successInfo } from "@state/success";
import iconCopy from "@common/img/svg/copy-icon.svg";
import { deleteObjectRequest, getObjectByKindRequest } from "@services/Objects";
import { GENERAL_NAMESPACE, ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import { IDeleteObjectRequest, IObjectsRequest } from "@screens/Objects/Objects.types";
import RootState from "@state/interfaces";
import base64ToJson from "@common/methods/base64toJSON";
import objectStore from "@state/object";
import { DiscoveryPoolStatusData } from "@screens/Pools/DiscoveryPool.types";
import { Modal } from "@components/Modal";
import iconRefresh from "@common/img/svg/refresh-icon.svg";
import { If } from "@components/If";
import iconDelete from "@common/img/svg/delete-icon.svg";
import iconSetting from "@common/img/svg/setting-icon.svg";
import { GLOBAL_PAGE, NAMESPACES_PAGE } from "@common/constant/urls";
import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";
import { defaultStatusPool } from "@screens/Pools/DiscoveryPool.utils";
import { checkObjectResponse } from "@common/methods/objectRequest";

import * as localStyle from "./Pool.style.scss";

export const StatusPoolContext = createContext<DiscoveryPoolStatusData>(defaultStatusPool);

const Pool = observer((): ReactElement => {
    const navigate = useNavigate();
    const { pool = "" } = useParams();
    const account = useSelector((state: RootState) => state.account);
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const [data, setData] = useState<DiscoveryPoolStatusData>(defaultStatusPool);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const canDelete = objectPermissions[ObjectEnum.DISCOVERY_POOL_CONFIG]?.includes(PermissionEnum.DELETE);

    const getDataStatus = useCallback(async (): Promise<void> => {
        const dataPoolStatus: IObjectsRequest = {
            kind: ObjectEnum.DISCOVERY_POOL_STATUS,
            metadata_only: false,
            name: pool,
            namespace: GENERAL_NAMESPACE,
        };

        const { data: dataStatus } = await getObjectByKindRequest(dataPoolStatus);

        if (!dataStatus || !dataStatus.object) {
            return;
        }

        const objectStatus = base64ToJson(dataStatus.object.data) as DiscoveryPoolStatusData;
        setData(objectStatus);
    }, [pool]);

    const getData = useCallback(async (): Promise<void> => {
        const dataPoolConfig: IObjectsRequest = {
            kind: ObjectEnum.DISCOVERY_POOL_CONFIG,
            name: pool,
            metadata_only: false,
            namespace: GENERAL_NAMESPACE,
        };

        const { data: dataConfig } = await getObjectByKindRequest(dataPoolConfig);

        if (!dataConfig || !dataConfig.object) {
            return;
        }
        const objectConfig = base64ToJson(dataConfig.object.data);

        objectStore.updateMetadata(dataConfig.object.metadata);
        objectStore.updateData(objectConfig);
    }, [pool]);

    const updateObject = async () => {
        Promise.all([getData(), getDataStatus()]).catch((e) => {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        });
    };

    const deleteObject = async () => {
        try {
            setIsDeleteModal(false);
            const {
                metadata: { kind, name, revision },
            } = objectStore.objectData;
            const object: IDeleteObjectRequest = {
                kind,
                name,
                namespace: GENERAL_NAMESPACE,
                requireRevision: revision,
                returnPrevious: false,
            };
            const { data } = await deleteObjectRequest(object);
            checkObjectResponse(data, () => navigate("/pools"));
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    useEffect(() => {
        if (!account.authorized) return () => {};
        getData().then();

        return () => {
            objectStore.resetObjectData();
        };
    }, [account.authorized, getData]);

    useEffect(() => {
        if (!account.authorized) return;
        getDataStatus().then();
    }, [account.authorized, getDataStatus]);

    return (
        <>
            <Modal
                isNegative
                isOpen={isDeleteModal}
                onClose={() => setIsDeleteModal(false)}
                title={window.locales.deleteObject}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteObject,
                }}
            >
                Do you want to delete the object {objectStore.objectData.metadata.name}?
            </Modal>
            <div className={commonStyle.mainPageContainer}>
                <div className={localStyle.poolHeader}>
                    <span className={localStyle.item}>Pool:</span> {pool}
                    <EnvIconContainer
                        action={() => {
                            successInfo.setSuccessInfoWithText(window.locales.copiedSuccess);
                            navigator.clipboard.writeText(pool).then();
                        }}
                    >
                        <img src={iconCopy} alt="Copy" />
                    </EnvIconContainer>
                    <div className={localStyle.poolHeader__actions}>
                        <EnvIconContainer
                            action={() =>
                                navigate(
                                    `/${NAMESPACES_PAGE}/${GLOBAL_PAGE}/${ObjectEnum.DISCOVERY_POOL_CONFIG}/${pool}`,
                                )
                            }
                        >
                            <img src={iconSetting} alt="img" />
                        </EnvIconContainer>
                        <EnvIconContainer action={() => updateObject()}>
                            <img src={iconRefresh} alt="img" />
                        </EnvIconContainer>
                        <If condition={canDelete}>
                            <EnvIconContainer action={() => setIsDeleteModal(true)}>
                                <img src={iconDelete} alt="img" />
                            </EnvIconContainer>
                        </If>
                    </div>
                </div>
                <div className={commonStyle.underTabsContainer}>
                    <NavLink
                        to="parameters"
                        className={({ isActive }) =>
                            `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`
                        }
                    >
                        {window.locales.parameters}
                    </NavLink>
                    <NavLink
                        to="servers"
                        className={({ isActive }) =>
                            `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`
                        }
                    >
                        {window.locales.servers}
                    </NavLink>
                    <NavLink
                        to="groups"
                        className={({ isActive }) =>
                            `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`
                        }
                    >
                        {window.locales.groups}
                    </NavLink>
                    <NavLink
                        to="locations"
                        className={({ isActive }) =>
                            `${commonStyle.underLink} ${isActive && commonStyle.activeUnderLink}`
                        }
                    >
                        {window.locales.locations}
                    </NavLink>
                </div>
                <StatusPoolContext.Provider value={data}>
                    <Outlet />
                </StatusPoolContext.Provider>
            </div>
        </>
    );
});

export default Pool;
