import React, { useState } from "react";
import { Field, Formik } from "formik";
import countryList from "country-list";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { useNavigate } from "react-router-dom";

import { CustomInput } from "@screens/Landing/Authorization/Input";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import StateList from "@constants/states";
import { If } from "@components/If";
import { CustomSelect } from "@components/FormikFields";
import * as buttonStyle from "@components/Button/style.scss";
import { isValidPwd } from "@common/isValidPwd";
import { getDateISO } from "@common/methods/getDateISO";
import { errorInfo } from "@state/error";

const optionsState = Object.entries(StateList).map(([value, label]) => ({
    value,
    label,
}));

const optionsCountry = Object.keys(countryList.getCodeList()).map((key) => {
    const country = key.toUpperCase();
    return {
        value: country,
        label: country,
    };
});

const SignUpForm = ({
    email = "test@test.com",
    goToSignIn,
}: {
    email: string;
    goToSignIn: (token: string) => void;
}) => {
    const [isError, setIsError] = useState(false);
    const [isProcess, setIsProcess] = useState(false);
    const navigate = useNavigate();

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signUpFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Sign up</div>
                </div>
                <Formik
                    initialValues={{
                        email,
                        password: "",
                        confirm_password: "",
                        first_name: "",
                        last_name: "",
                        company: "",
                        project_prefix: "",
                        country: "",
                        state: "",
                        city: "",
                    }}
                    validate={(values) => {
                        const errors: {
                            password?: string;
                            confirm_password?: string;
                            first_name?: string;
                            last_name?: string;
                            company?: string;
                            project_prefix?: string;
                        } = {};

                        const {
                            invalidCompanyName,
                            invalidFirstName,
                            incorrectProjectPrefix,
                            invalidLastName,
                            invalidProjectPrefix,
                            weakPassword,
                            invalidEmptyPassword,
                            invalidPasswordMatch,
                        } = window.locales;
                        const { password, confirm_password, first_name, last_name, company, project_prefix } = values;

                        if (!password) errors.password = invalidEmptyPassword;
                        if (!confirm_password) errors.confirm_password = invalidEmptyPassword;
                        if (confirm_password !== password) {
                            errors.confirm_password = invalidPasswordMatch;
                        }

                        if (!first_name) errors.first_name = invalidFirstName;
                        if (!last_name) errors.last_name = invalidLastName;
                        if (!company) errors.company = invalidCompanyName;
                        if (!project_prefix) errors.project_prefix = invalidProjectPrefix;
                        if (project_prefix?.length > 8) errors.project_prefix = incorrectProjectPrefix;

                        if (password.length < 8) errors.password = weakPassword;
                        setIsError(false);
                        return errors;
                    }}
                    onSubmit={(
                        { password, first_name, last_name, company, project_prefix, country, state, city },
                        { setErrors },
                    ) => {
                        setIsProcess(true);
                        isValidPwd(email, password).then(({ data }) => {
                            if (!data) {
                                setIsProcess(false);
                                setErrors({
                                    password: window.locales.weakPassword,
                                });
                                return;
                            }

                            const auth = getAuth();
                            createUserWithEmailAndPassword(auth, email, password)
                                .then(async (userCredential) => {
                                    const { user } = userCredential;
                                    const token = await user.getIdToken();
                                    const db = getDatabase();
                                    await set(ref(db, `users/${user.uid}`), {
                                        email,
                                        tos: getDateISO(),
                                        first: true,
                                        first_name,
                                        last_name,
                                        company,
                                        projectPrefix: project_prefix,
                                        country,
                                        state,
                                        city,
                                    });

                                    goToSignIn(token);
                                })
                                .catch((error) => {
                                    navigate("/");
                                    errorInfo.setErrorInfo({
                                        title: error.code,
                                        description: error.message,
                                    });
                                })
                                .finally(() => {
                                    setIsProcess(false);
                                    setIsError(false);
                                });
                        });
                    }}
                >
                    {({ values, handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                            <section>
                                <div className={authorizationStyle.titleSection}>Personal data</div>
                                <div className={authorizationStyle.fieldsContainer}>
                                    <div className={authorizationStyle.fieldContainer}>
                                        <Field
                                            component={CustomInput}
                                            value={values.email}
                                            type="text"
                                            name="email"
                                            disabled
                                            placeholder="Email"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className={authorizationStyle.doubleFieldContainer}>
                                        <Field
                                            component={CustomInput}
                                            value={values.first_name}
                                            type="text"
                                            name="first_name"
                                            placeholder="Jack"
                                            autoComplete="off"
                                        />
                                        <Field
                                            component={CustomInput}
                                            value={values.last_name}
                                            type="text"
                                            name="last_name"
                                            placeholder="Sparrow"
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className={authorizationStyle.doubleFieldContainer}>
                                        <Field
                                            component={CustomInput}
                                            value={values.password}
                                            type="password"
                                            name="password"
                                            placeholder="New password"
                                            autoComplete="new-password"
                                        />
                                        <Field
                                            component={CustomInput}
                                            value={values.password}
                                            type="password"
                                            name="confirm_password"
                                            placeholder="Confirm Password"
                                            autoComplete="new-password"
                                        />
                                    </div>
                                    <div className={authorizationStyle.doubleFieldContainer}>
                                        <Field
                                            component={CustomInput}
                                            value={values.company}
                                            type="text"
                                            name="company"
                                            placeholder="Aura"
                                            autoComplete="off"
                                        />
                                        <Field
                                            component={CustomInput}
                                            value={values.project_prefix}
                                            type="text"
                                            name="project_prefix"
                                            placeholder="aur"
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </section>
                            <section>
                                <div className={authorizationStyle.titleSection}>Location data</div>
                                <div className={authorizationStyle.fieldsContainer}>
                                    <div className={authorizationStyle.doubleFieldContainer}>
                                        <div className={authorizationStyle.selectWrapper}>
                                            <Field
                                                name="country"
                                                selected={values.country}
                                                placeholder="Country"
                                                component={CustomSelect}
                                                options={optionsCountry}
                                            />
                                        </div>
                                        <If condition={values.country !== "US"}>
                                            <Field
                                                component={CustomInput}
                                                value={values.state}
                                                type="text"
                                                name="state"
                                                placeholder="State"
                                            />
                                        </If>
                                        <If condition={values.country === "US"}>
                                            <div className={authorizationStyle.selectWrapper}>
                                                <Field
                                                    component={CustomSelect}
                                                    name="state"
                                                    selected={values.state}
                                                    placeholder="State"
                                                    options={optionsState}
                                                />
                                            </div>
                                        </If>
                                    </div>
                                    <div className={authorizationStyle.fieldContainer}>
                                        <Field
                                            component={CustomInput}
                                            value={values.city}
                                            type="text"
                                            name="city"
                                            placeholder="Paris"
                                        />
                                    </div>
                                </div>
                            </section>
                            {isError && (
                                <div className={authorizationStyle.errorContainer}>{window.locales.signInError}</div>
                            )}
                            <div className={authorizationStyle.buttonContainer}>
                                <button
                                    className={buttonStyle.buttonSubmit}
                                    disabled={isError || isProcess}
                                    type="submit"
                                >
                                    Sign up
                                </button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SignUpForm;
