import React, { ReactElement } from "react";

import ConfigMetadata from "@screens/Pools/Pool/Parameters/PoolConfigMetadata";
import PoolConfigData from "@screens/Pools/Pool/Parameters/PoolConfigData";

import * as localStyle from "./ConfigParameters.style.scss";

const ConfigParameters = (): ReactElement => (
    <div className={localStyle.configParametersPage}>
        <ConfigMetadata />
        <PoolConfigData />
    </div>
);

export default ConfigParameters;
