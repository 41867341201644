// extracted by mini-css-extract-plugin
var _1 = "style-backToSignInButton__QRsV3";
var _2 = "style-buttonContainer__SYBbv";
var _3 = "style-buttonEdit__XfGmr";
var _4 = "style-buttonResetPassword__rW8YT";
var _5 = "style-buttonSubmit__CzKZm";
var _6 = "style-doubleFieldContainer__cZKGE";
var _7 = "style-errorContainer__E65WH";
var _8 = "style-fieldContainer__vArcB";
var _9 = "style-fieldInput__t8vgr";
var _a = "style-fieldsContainer__oQAiq";
var _b = "style-formCenter__yB8VX";
var _c = "style-formContainer__u7gOM";
var _d = "style-infoContainer__j5xKp";
var _e = "style-input__I5B9G";
var _f = "style-inputContainer__Go2S4";
var _10 = "style-inputIcon__smMy3";
var _11 = "style-inputName__b9hLY";
var _12 = "style-mfaButtons__lQKxf";
var _13 = "style-notificationContainer__bZ4F3";
var _14 = "style-qrCodeContainer__Xvdnd";
var _15 = "style-selectWrapper__Is8ii";
var _16 = "style-signFormContainer__fOZqz";
var _17 = "style-signUpFormContainer__nshHM";
var _18 = "style-text__ZRuj6";
var _19 = "style-title__ee7FQ";
var _1a = "style-titleContainer___hSCY";
var _1b = "style-titleSection__AChqW";
export { _1 as "backToSignInButton", _2 as "buttonContainer", _3 as "buttonEdit", _4 as "buttonResetPassword", _5 as "buttonSubmit", _6 as "doubleFieldContainer", _7 as "errorContainer", _8 as "fieldContainer", _9 as "fieldInput", _a as "fieldsContainer", _b as "formCenter", _c as "formContainer", _d as "infoContainer", _e as "input", _f as "inputContainer", _10 as "inputIcon", _11 as "inputName", _12 as "mfaButtons", _13 as "notificationContainer", _14 as "qrCodeContainer", _15 as "selectWrapper", _16 as "signFormContainer", _17 as "signUpFormContainer", _18 as "text", _19 as "title", _1a as "titleContainer", _1b as "titleSection" }
