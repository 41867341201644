export default {
    currentPlan: "現在の計画",
    addLocation: "場所を追加",
    updateToS: "更新された利用規約",
    repay: "返済",
    city: "市",
    line1: "行 1",
    line2: "行 2",
    postalCode: "郵便番号",
    state: "状態",
    promoCode: "プロモーションコード",
    phone: "電話",
    firstName: "名",
    apply: "適用する",
    lastName: "姓",
    trialPeriod: "1か月無料トライアル",
    company: "企業名",
    companyCode: "会社コード",
    choosePassword: "パスワードを選択してください",
    repeatPassword: "パスワードを再度入力してください",
    whichProduct: "最初に使用したい製品はどれですか",
    numberOfUsers: "アプリの1日のユーザー数",
    tos: "利用規約に同意してください",
    downgradeNow: "今すぐダウングレード",
    signUpProducts: ["探査中です", "プロキシ - あなたのアプリのみを保護", "VPN - OS全体の保護", "その他"],
    signUpUsers: ["100,000未満", "100,001以上", "500,001以上", "1,000,001以上", "生産アプリではない"],
    verify1: "確認メールが指定されたメールボックスに送信されました。",
    verify2: "アプリケーションの使用を開始するには、アカウントを確認してください",
    haveAccount: "アカウントを持っています",
    mainPage: "メインページ",
    resetLink: "メールに送信されたリンクをリセットしました。",
    dontHaveAccount: "アカウントを持っていません",
    addNetwork: "ネットワークを追加",
    version: "バージョン",
    build: "ビルド",
    location: "ロケーション",
    protocols: "プロトコル",
    public: "パブリック",
    oneDay: "1日",
    sevenDays: "7日",
    fourteenDays: "14日",
    thirtyDays: "30日",
    admin: "管理者",
    registeredUsers: "登録ユーザー",
    registeredDevices: "登録済みデバイス",
    default: "初期設定",
    light: "光",
    white: "白",
    blue: "青",
    list: "リスト",
    card: "カード",
    english: "英語",
    japanese: "日本語",
    close: "閉じる",
    daily: "デイリー",
    weekly: "週間",
    monthly: "月間",
    documentation: "ドキュメンテーション",
    menuProducts: "メニューと製品",
    all: "全員",
    quickstart: "クイックスタート",
    api: "APIリファレンス",
    sdk: "SDK",
    search: "検索",
    product: "製品",
    platform: "プラットフォーム",
    protocol: "プロトコル",
    main1: "サービスとしての自由性",
    main2: "抑制されたり制限されたりすることのない",
    main3: "安全なアプリの構築",
    main4: "無料のAPIキーを取得する",
    main5: "詳細を確認",
    main6: "ユーザーを保護する",
    main7: "VPN SDK",
    main8: "ユーザーのオペレーティングシステム全体を保護するためのVPNを有効にしてください。悪意のあるWebサイト、ISP、およびハッカーからユーザーのデータを保護します。安全でないネットワーク（公衆Wi-Fiなど）から保護します。OS全体を管理できます。",
    main9: "止めようのないアプリに",
    main10: "プロキシSDK",
    main11: "インターネットサービスプロバイダからの抑制、検閲、またはあらゆる種類の干渉からアプリを保護します。ISPやハッカーからユーザーデータを保護します。アプリが世界中のどこからでもいつでも利用できるようにします。",
    main12: "取引先の保護",
    main13: "企業向けVPN",
    main14: "AnchorFree独自の超高速なHydra VPNとAnchorFree Server ComponentNetwork（世界100大ネットワークの1つ）をご使用ください。詳細については、当社パートナーシップチームにお問い合わせください。",
    main15: "当社の主な特徴",
    main16: "プロトコルとプラットフォーム",
    main17: "当社は一般的なVPNプロトコルと主要なクライアントプラットフォームをサポートしています",
    main18: "当社は次の一般的なVPNプロトコルをサポートしています。",
    main19: "そして次の主要なクライアントプラットフォームをサポートしています。",
    main20: "追加設定なしですぐにご使用いただけます。",
    main21: "既存のアプリはもちろん新しいアプリケーションでも簡単にお使いいただけます",
    main22: "アクセシビリティを重視",
    main23: "世界中の何千ものサーバーエンドポイントから選択するか、ユーザーにとって最速のものを選択してください",
    main24: "プライバシーを重要視",
    main25: "個々のユーザーデータやトラフィックを保存、記録、監視することはありません",
    main26: "管理が簡単",
    main27: "アプリの監視と管理はアカウント内の1つのダッシュボードから可能です",
    main28: "始める準備はいいですか?",
    main29: "無料",
    main30: "無料のAPIキーを取得する",
    features: "機能",
    help: "マニュアル",
    docs: "ドキュメント",
    signIn: "サインイン",
    signUp: "サインアップ",
    products: "製品",
    ourPartners: "当社パートナー",
    tryIt: "今すぐ試す",
    allProjects: "すべてのプロジェクト",
    addProject: "プロジェクトを追加",
    searchProject: "プロジェクトを検索",
    id: "ID",
    name: "デバイス名",
    accountName: "アカウント名",
    activatedDevices: "アクティブなデバイス",
    condition: "ステータス",
    registrationTime: "登録日時",
    registrationDate: "登録日",
    off: "オフ",
    secondSymbol: "秒",
    minuteSymbol: "分",
    hourSymbol: "時間",
    month: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    daySymbol: "日",
    yearSymbol: "年",
    searchUsers: "ユーザーを検索",
    uid: "ユーザーID",
    unm: "ユーザー名",
    utk: "ユーザートークン",
    uex: "ユーザーExtref",
    did: "デバイスID",
    add: "追加",
    username: "ユーザー名",
    email: "Eメール",
    fieldPassword: "パスワード",
    licence: "ライセンス",
    authentifications: "認証方式",
    extref: "Extref",
    cancel: "キャンセル",
    saveUser: "ユーザーを追加",
    provider: "プロバイダ",
    traffic: "トラフィック",
    trafficStart: "開始",
    trafficUsed: "使用済み",
    trafficRemaining: "残り",
    trafficLimit: "制限",
    enabled: "有効",
    disabled: "無効",
    paid: "有料",
    free: "無料",
    unlimited: "無制限",
    limited: "制限",
    sessions: "セッション",
    purchases: "購入",
    social: "ソーシャル",
    allDevices: "すべてのデバイス",
    today: "本日",
    thisWeek: "今週",
    thisMonth: "今月",
    yesterday: "昨日",
    lastWeek: "先週",
    lastMonth: "先月",
    activeSessions: "アクティブセッション",
    noSessionsRow1: "選択された期間にセッションはありません",
    noSessionsRow2: "他の期間をしてください。",
    noDeviceRow: "ユーザーにデバイスはありません",
    CREATE_CARRIER_COUNTRY: "ネットワークを追加",
    noSocialRow: "ユーザーにソーシャルはありません",
    noPurchasesRow: "ユーザーに購入があります",
    accessToken: "アクセストークン",
    deviceType: "デバイスタイプ",
    deleteDevice: "デバイスの削除",
    deleteDeviceConfirmation: "デバイスを削除してもよろしいですか?",
    transactionId: "トランザクションID",
    productId: "製品ID",
    type: "タイプ",
    transactionTime: "トランザクション時間",
    expirationTime: "有効期限",
    active: "有効",
    subscription: "定期購買",
    trial: "試用版",
    rawData: "生データ",
    disablePurchaseConfirmation: "購入を無効にしてもよろしいですか?",
    enablePurchaseConfirmation: "購入を有効にしてもよろしいですか?",
    activatePurchase: "購入を有効にする",
    disablePurchase: "購入を無効にする",
    value: "値",
    editUser: "ユーザーの編集",
    editLimit: "制限の設定",
    limit: "制限",
    saveChanges: "変更内容を保存",
    trafficReset: "トラフィックのリセット",
    setTrafficUnlimited: "トラフィックを無制限に設定する",
    deleteData: "ユーザーデータの削除",
    userData: "ユーザーデータ",
    disableUser: "ユーザーを無効にする",
    enableUser: "ユーザーを有効にする",
    disableUserDescription: "ユーザーを無効にしてもよろしいですか?",
    enableUserDescription: "ユーザーを有効にしてもよろしいですか?",
    network: "ネットワーク",
    settings: "設定",
    noUsers: "プロジェクトにはユーザーがいません",
    deleteAuthMethod: "認証方式の削除",
    deleteAuthMethodConfirmation: "認証方式を削除してもよろしいですか?",
    editAuthMethod: "認証方式の編集",
    authMethod: "認証方式",
    authSettings: "認証設定",
    addAuthMethod: "新しい認証方式",
    paymentsMethod: "支払い方法",
    paymentsSettings: "支払い設定",
    addPaymentsMethodMethod: "新しい支払い方法",
    editPaymentsMethod: "支払い方法の編集",
    noPayments: "プロジェクトに支払い方法がありません",
    noAuthentifications: "プロジェクトに認証方式がありません",
    internalServerError: "内部サーバーエラー",
    deletePaymentsMethod: "支払い方法の削除",
    deletePaymentsMethodConfirmation: "支払い方法を削除してもよろしいですか?",
    role: "ロール",
    addUser: "ユーザーを追加",
    deleteUser: "ユーザーの削除",
    deleteUserConfirmation: "ユーザーを削除してもよろしいですか?",
    parameters: "一般",
    projectName: "プロジェクト名",
    deleteProject: "プロジェクトの削除",
    deleteProjectConfirmation: "プロジェクトを削除してもよろしいですか?",
    publickey: "プロジェクトID",
    privatekey: "秘密キー",
    description: "説明",
    projectType: "プロジェクトタイプ",
    createProject: "プロジェクトタイプ",
    adminOptional: "管理者のメールアドレス（オプション）",
    changeRole: "ロールの変更",
    invited: "未認証",
    general: "一般",
    member: "メンバー",
    addMember: "メンバーを追加",
    deleteMember: "メンバーの削除",
    noProjects: "プロジェクトはありません",
    dashboard: "ダッシュボード",
    signOut: "ログアウト",
    theme: "テーマ",
    locales: "言語",
    viewProfile: "プロファイルを表示",
    invalidPrefixId: "プロファイルにプレフィックスIDを追加してください",
    profile: "プロファイル",
    mainInfo: "主な情報",
    addressInfo: "住所情報",
    resetPassword: "パスワードのリセット",
    reseted: "完了。リンクをメールアドレスに送信しました。",
    url: "URL",
    bandwidthType: "通信量タイプ",
    config: "設定",
    networkTemplate: "ネットワークテンプレート",
    passwordOptional: "パスワード（オプション）",
    givenName: "名",
    locations: "ロケーション",
    authorizationType: "承認タイプ",
    noProjectSessions: "現在、アクティブセッションはありません",
    country: "国",
    enterCorrectEmail: "正しいメールアドレスを入力してください",
    fillInFields: "強調表示されたフィールドを入力してください。",
    uploadSize: "ファイルの最大サイズは5Mbです。",
    uploadFormats: "jpg、jpeg、png、またはgifを使用してください",
    fullMonth: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
    fullWeek: ["日", "月", "火", "水", "木", "金", "土"],
    from: "開始",
    till: "終了",
    server: "サーバ",
    startTime: "開始時間",
    endTime: "終了時間",
    internalIp: "内部IP",
    download: "ダウンロード",
    upload: "アップロード",
    by_user: "ユーザー別",
    by_device: "デバイス別",
    proxy: "プロキシ",
    public_vpn: "パブリックVPN",
    private_vpn: "プライベートVPN",
    icon: "アイコン",
    uploadImage: "画像をアップロードする",
    roles: {
        OWNER: {
            name: "オーナー",
            description: "オーナー",
        },
        ADMIN: {
            name: "管理者",
            description: "管理者",
        },
        SUPPORT: {
            name: "サポート",
            description: "サポート",
        },
    },
    confirm: "決定",
    locationsList: {
        no: "ノルウェー",
        de: "ドイツ",
        hk: "香港",
        ru: "ロシア連邦",
        jp: "日本",
        dk: "デンマーク",
        fr: "フランス",
        ua: "ウクライナ",
        sc: "セイシェル",
        br: "ブラジル",
        se: "スウェーデン",
        sg: "シンガポール",
        gb: "イギリス",
        id: "インドネシア",
        ie: "アイルランド",
        ca: "カナダ",
        us: "アメリカ",
        ch: "スイス",
        in: "インド",
        mx: "メキシコ",
        it: "イタリア",
        es: "スペイン",
        ar: "アルゼンチン",
        au: "オーストラリア",
        "us-south": "アメリカ南部",
        cz: "チェコ共和国",
        ro: "ルーマニア",
        tr: "トルコ",
        nl: "オランダ",
    },
    sessions_by_protocol: "アクティブなセッション",
    dataRefresh: "データ更新",
    currentData: "現在のデータ",
    sessions_by_location: "ロケーション別のアクティブセッション",
    devices: "ログインデバイス",
    noData: "データなし",
    sorryNoData: "データが見つかりませんでした",
    connected_users: "接続ユーザー",
    inactive_users: "非アクティブなユーザー",
    inactive_devices: "接続デバイス",
    network_traffic: "ネットワークトラフィック",
    connected_devices: "非アクティブなデバイス",
    location_loading_table: "国別使用状況",
    location_loading_map: "国別使用状況（地図）",
    defaultBandwidthLimit: "デフォルトの帯域幅制限（Mb)",
    supportIos: "iOS",
    supportAndroid: "Android",
    supportMacos: "MacOS",
    supportWindows: "Windows",
    checkboxSupportTitle: "サポートするOSを選択",
    defaultLicence: "デフォルトライセンス",
    clientSupport: "クライアントのサポート",
    bulkUserDataUpload: "ユーザーを一括で追加します",
    bulkUserAddCsv: "ファイルを選択",
    bulkUserError: "ファイル形式が正しくありません",
    bulkUserMalformed: "ファイルが選択されていません",
    bulkUserEmpty: "ファイルが選択されていません",
    dashboardSafari: "SafariはDashboardをサポートしていません",
    noInvoices: "請求書はありません",
    exportData: "エクスポート",
    users: "ユーザー",
    exportUsersData: "ユーザーデータのエクスポート",
    exportUsersDataText: "ユーザーデータをエクスポートしますか？",
    exportDevicesData: "デバイスデータのエクスポート",
    exportDevicesDataText: "デバイスデータをエクスポートしますか？",
    exportSessionsData: "セッションデータのエクスポート",
    exportSessionsDataText: "セッションログをエクスポートしますか？",
    vpnBypassList: "VPNバイパスリスト",
    addBypass: "バイパスドメインを追加する",
    domain: "ドメイン",
    save: "保存する",
    searchDomains: "ドメインの検索",
    status: "状態",
    done: "完了",
    error: "エラー",
    errorMessage: "エラーメッセージ",
    error1: "パスワードは6文字以上必要です",
    error2: "ユーザーIDは6文字以上必要です",
    error3: "ユーザーIDには次の文字列が使えます。A-Z, a-z, 0-9, _",
    error4: "Eメールアドレスに利用できない文字が含まれています",
    error5: "既に登録されています",
    error6: "ファイルにデータがありません",
    downloadExample: "サンプルファイルのダウンロード",
    reportCreated: "作成されたレポート",
    created: "作成日",
    exportFrom: "集計開始日",
    exportTill: "集計終了日",
    exportStatus: "結果",
    exportUsers: "ユーザー",
    exportDevices: "デバイス",
    exportSessions: "セッションログ",
    BY_CARRIER: "セッション",
    BY_ALL_USERS: "ユーザー",
    BY_ALL_DEVICES: "デバイス",
    BY_USER: "ユーザー",
    BY_USER_ID: "ユーザーID",
    NEW: "進行中",
    EXPORTING: "進行中",
    COMPLETED: "完了",
    EXPORTING_FAILED: "失敗",
    QUERYING_FAILED: "失敗",
    domainNotValid: "無効なドメインです",
    usersAdd: "ユーザーを追加",
    usersAddOne: "追加",
    usersAddMultiple: "一括追加",
    exportAdd: "エクスポート",
    bulkUserLogStatus: "結果",
    domainsSaveQuestionTitle: "変更を保存します",
    domainsSaveQuestionText: "無効または重複したドメインは削除されます",
    domainsSaveQuestionCancel: "キャンセル",
    domainsSaveQuestionSubmit: "保存",
    "The password is invalid or the user does not have a password.": "既に登録されています",
    exportDataSubmit: "エクスポート",
    updateSelectedUsers: "選択して更新",
    updateSelectedUsersBtn: "更新",
    updateSelectedUsersTitle: "一括更新",
    updateSelectedUsersCbTitle: "選択して更新",
    selectField: "項目を選択",
    setNewValue: "Please enter new value",
    massUpdate: "一括更新",

    devicesNumber: "デバイス数",
    devicesAny: "すべて選択",
    devicesMore: "指定の値より多い",
    devicesLess: "指定の値より少ない",
    devicesExact: "指定の値と等しい",
    usersAll: "全て",
    usersActive: "有効",
    usersDisabled: "無効",
    usersInvited: "未認証",
    usersActiveAndInvited: "有効と未認証",
    find: "検索",
    reset: "リセット",
    applications: "インストーラー",
    downloadUserUpdateLog: "結果をダウンロード",
    dashboardGeneral: "接続状況",
    dashboardLocation: "パブリックサーバーの使用状況",
    log: "管理者ログ",
    invoices: "請求書",
    eventDate: "日付",
    eventAuthor: "管理者",
    eventType: "種別",
    eventChanges: "内容",
    loadMore: "さらに表示",
    filters: "フィルター",
    invoiceNumber: "請求書番号",
    billingPeriod: "支払請求周期",
    amount: "量",
    payStatus: "支払い状況",
    pdf: "pdf",

    events: {
        CREATE_FIREBASE_ADMIN: "新しいメンバー",
        UPDATE_CARRIER_FIREBASE_ADMIN_ROLE: "ロールの変更",
        DELETE_CARRIER_FIREBASE_ADMIN: "メンバーを削除",
        CREATE_CARRIER_TEST_PURCHASE_SETTINGS: "新しい支払い方法",
        UPDATE_CARRIER_TEST_PURCHASE_SETTINGS: "支払い方法の変更",
        DELETE_CARRIER_PURCHASE_SETTINGS: "支払い方法の削除",
        CREATE_AUTH_METHOD: "新しい認証方法",
        DELETE_CARRIER_AUTH_METHOD: "認証方法を削除",
        UPDATE_CARRIER_AUTH_METHOD: "認証方法を変更する",
        UPDATE_CARRIER: "プロジェクトの設定変更",
        UPDATE_CARRIER_ICON: "更新アイコン",
        ADD_FILE: "ファイル",
        CREATE_SUBSCRIBER: "新しいユーザー",
        UPDATE_SUBSCRIBER: "ユーザーの更新",
        MASS_USER_UPDATE: "ユーザーデータの変更",
        DELETE_USER_DATA: "ユーザーの削除",
        SET_TRAFFIC_LIMIT: "トラフィックの設定",
        DELETE_TRAFFIC_LIMIT: "トラフィックの削除",
        DELETE_DEVICE: "デバイスの削除",
        CREATE_CARRIER_COUNTRY: "ロケーションの追加",
        DELETE_CARRIER_COUNTRY: "ロケーションの削除",
        DISABLE_USER: "ユーザーの無効化",
        PARTNER_LOGIN: "Log In",
        PARTNER_LOGOUT: "Log Out",
        GENERATE_REPORT: "レポートを作成",
        CREATE_FIREBASE_ADMIN_CHANGES1: "ユーザー：",
        CREATE_FIREBASE_ADMIN_CHANGES2: ", ロール：",
        UPDATE_CARRIER_ICON_CHANGES: "アイコンのアップロード",
        ADD_FILE_CHANGES: "バイパスリストのアップロード",
        USER: "ユーザー",
        DEVICE: "デバイス",
    },

    custom: "カスタム",
    period: "更新間隔",
    anyDate: "すべて選択",
    selectPlaceholderDefault: "選択する",
    selectPlaceholderType: "種別を選択",
    interval: "期間",
    dashboardInterval: " ",
    any: "どれか",
    clientNetworks: "クライアントネットワーク",
    enable: "有効にする",
    disable: "無効にする",
    yes: "はい",
    no: "いいえ",
    doesntMatter: "関係ない",
    ssidLabel: "SSID、コンマ区切り",
    bssidLabel: "BSSID、コンマ区切り",
    authorized: "承認済み",
    action: "アクション",
    addClientNetworkTitle: "クライアントネットワークレコードを追加する",
    editClientNetworkTitle: "クライアントネットワークレコードの編集",
    deleteClientNetworkTitle: "クライアントネットワークレコードを削除する",
    deleteClientNetworkText: "クライアントネットワークレコードを削除しますか？",
    delete: "削除する",
    fullTextSearchMsg: "全文検索には少なくとも3文字が必要です",
    authors: "管理者",

    DONE: "完了",
    SKIPPED: "変更なし",
    NO_VALUES: "空の項目が含まれています",
    WRONG_VALUE: "使用できない文字または正しくないステータスが含まれています",
    noChanges: "変更はありません",
    optimalLocation: "ネットワーク",

    itAssets: "IT 資産",
    noItAssets: "IT 資産がない",
};
