import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

import Authorization from "@screens/Landing/Authorization";
import { EAuthorizationStatus } from "@interfaces";
import { getActionCode } from "@common/getActionCode";
import { If } from "@components/If";
import { Pending } from "@screens/Panding";
import * as style from "@screens/Landing/style.scss";
import rightWorldSVG from "@common/img/svg/right-side-world.svg";
import leftWorldSVG from "@common/img/svg/left-side-world.svg";
import { Form } from "@screens/Landing/Authorization/types";
import { AuthContext } from "@common/auth-context";
import { DISABLE_MFA_STEP } from "@screens/Profile/AccountSecurity/TwoFactoryAuth/TwoFactoryAuthForm";

const Landing = ({ initPage }: { initPage: Form }) => {
    const [authorizationStatus] = useContext(AuthContext);
    const actionCode = getActionCode();

    if (EAuthorizationStatus.Authorized === authorizationStatus && !actionCode) {
        const isDisableMFAStep = sessionStorage.getItem(DISABLE_MFA_STEP);
        return isDisableMFAStep ? <Navigate replace to="/profile/security" /> : <Navigate replace to="/projects" />;
    }

    return (
        <>
            <If condition={EAuthorizationStatus.Unauthorized === authorizationStatus || !!actionCode}>
                <div className={style.landingPageContainer}>
                    <div className={style.backgroundImg}>
                        <img src={leftWorldSVG} alt="right-side-world" />
                        <img src={rightWorldSVG} alt="right-side-world" />
                    </div>
                    <div className={style.landingPage}>
                        <Authorization initPage={initPage} />
                        <div className={style.footerContainer}>
                            <nav>
                                <a target="_blank" href="https://auravpn.gitbook.io/paas/" rel="noreferrer">
                                    Documentation
                                </a>
                                <a target="_blank" href="https://www.aura.com/legal/privacy-policy" rel="noreferrer">
                                    Privacy Policy
                                </a>
                                <a target="_blank" href="https://www.aura.com/legal" rel="noreferrer">
                                    Terms of Service
                                </a>
                            </nav>
                            <span>© Pango 2024</span>
                        </div>
                    </div>
                </div>
            </If>
            <If condition={EAuthorizationStatus.Pending === authorizationStatus}>
                <Pending />
            </If>
        </>
    );
};

export default Landing;
