import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import KindForm from "@screens/Objects/Object/KindForm";
import KindConfig from "@screens/Objects/Object/KindConfig";
import KindMetadata from "@screens/Objects/Object/ObjectMetadata";
import { ObjectTab, PermissionEnum, ObjectEnum } from "@screens/Objects/Object/Object.constants";
import KindHeader from "@screens/Objects/Object/KindHeader";
import { deleteObjectRequest, editAndCreateObjectRequest, getObjectByKindRequest } from "@services/Objects";
import base64ToJson from "@common/methods/base64toJSON";
import { GLOBAL_PAGE, NAMESPACES_PAGE, NEW_PAGE } from "@common/constant/urls";
import objectStore from "@state/object";
import * as buttonStyle from "@components/Button/style.scss";
import { IDeleteObjectRequest, IObjectRequest } from "@screens/Objects/Objects.types";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import jsonToBase64 from "@common/methods/jsonToBase64";
import filterEmptyStrings from "@common/methods/filterEmptyStringValues";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import iconDelete from "@common/img/svg/delete-icon.svg";
import iconRefresh from "@common/img/svg/refresh-icon.svg";
import { If } from "@components/If";
import { Modal } from "@components/Modal";
import * as commonStyle from "@common/style/style.scss";
import { checkObjectResponse } from "@common/methods/objectRequest";

import * as localeStyle from "./Object.style.scss";

const Kind = (): ReactElement => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [isUpdateModal, setIsUpdateModal] = useState(false);
    const [tab, setTab] = useState(ObjectTab.JSON);
    const { name, kind, namespace = "" } = useParams();
    const [schema, setSchema] = useState({});
    const [permissions, setPermissions] = useState<string[]>([]);
    const navigate = useNavigate();
    const canCreateAndEdit = permissions.includes(PermissionEnum.PUT);
    const canDelete = permissions.includes(PermissionEnum.DELETE);

    const getPermissions = useCallback(async () => {
        const { data } = await getObjectByKindRequest({
            kind: ObjectEnum.PERMISSION,
            name: kind,
            namespace: namespace !== GLOBAL_PAGE ? namespace : "",
        });

        if (!data || !data.object) {
            return;
        }
        const { ops } = base64ToJson(data.object.data);

        if (!ops.includes(PermissionEnum.GET)) {
            errorInfo.setErrorInfo({
                title: window.locales.accessDenied,
                description: window.locales.readDenied,
            });

            navigate(`/${NAMESPACES_PAGE}/${namespace}/${kind}`);
        }
        setPermissions(ops);
    }, [kind, namespace, navigate]);

    const getObjectSchema = useCallback(async () => {
        const { data } = await getObjectByKindRequest({
            kind: ObjectEnum.SCHEMA,
            name: kind,
        });

        if (!data || !data.object) {
            return;
        }

        const objectSchema = base64ToJson(data.object.data);
        setSchema(objectSchema);
    }, [kind]);

    const deleteObject = async () => {
        try {
            setIsDeleteModal(false);
            const {
                metadata: { kind, name, revision },
            } = objectStore.objectData;
            const object: IDeleteObjectRequest = {
                kind,
                namespace: namespace !== GLOBAL_PAGE ? namespace : "",
                name,
                requireRevision: revision,
                returnPrevious: false,
            };
            const { data } = await deleteObjectRequest(object);
            checkObjectResponse(data, () => navigate(`/${NAMESPACES_PAGE}/${namespace}/${kind}`));
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    const getObjectData = useCallback(async () => {
        if (!name || !kind) {
            return;
        }

        const { data } = await getObjectByKindRequest({
            kind,
            name: decodeURIComponent(name),
            namespace: namespace !== GLOBAL_PAGE ? namespace : "",
        });

        if (!data || !data.object) {
            return;
        }

        const object = base64ToJson(data.object.data);
        objectStore.updateMetadata({ ...data.object.metadata });
        objectStore.updateData({ ...object });
    }, [kind, name, namespace]);

    useEffect(() => {
        if (name !== NEW_PAGE) {
            getObjectData().then();
        }

        if (kind) {
            getPermissions().then();
            getObjectSchema().then();
        }

        return () => {
            objectStore.resetObjectData();
        };
    }, [getObjectData, getObjectSchema, getPermissions, kind, name]);

    const saveButton = async () => {
        try {
            if (!isButtonDisabled) {
                setIsButtonDisabled(true);
            }

            if (!canCreateAndEdit) {
                errorInfo.setErrorInfo({
                    title: window.locales.accessDenied,
                    description: window.locales.saveDenied,
                });
                return;
            }

            const dataObject =
                typeof objectStore.objectData.data === "string"
                    ? JSON.parse(objectStore.objectData.data)
                    : objectStore.objectData.data;

            const object = filterEmptyStrings({
                ...objectStore.objectData.metadata,
                requireRevision: objectStore.objectData.metadata.revision,
                data: jsonToBase64(dataObject),
                kind,
                namespace: namespace !== GLOBAL_PAGE ? namespace : "",
                onlyCreate: false,
                onlyUpdate: false,
                returnPrevious: false,
                returnCurrent: true,
            }) as IObjectRequest;

            const { data } = await editAndCreateObjectRequest({
                ...object,
                onlyCreate: name === NEW_PAGE,
                onlyUpdate: name !== NEW_PAGE,
            });

            if (!data) return;

            checkObjectResponse(data, () => {
                if (name === NEW_PAGE) {
                    navigate(`/${NAMESPACES_PAGE}/${namespace}/${kind}`);
                } else {
                    const object = base64ToJson(data.current.data);
                    objectStore.updateMetadata({ ...data.current.metadata });
                    objectStore.updateData({ ...object });
                }
            });
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        } finally {
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 2000);
        }
    };

    const updateObject = async () => {
        setIsUpdateModal(false);
        await getObjectData();
    };

    return (
        <div className={commonStyle.mainPageContainer}>
            <Modal
                isNegative
                isOpen={isDeleteModal}
                onClose={() => setIsDeleteModal(false)}
                title={window.locales.deleteObject}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteObject,
                }}
            >
                Do you want to delete the object {objectStore.objectData.metadata.name}?
            </Modal>
            <Modal
                isNegative
                isOpen={isUpdateModal}
                onClose={() => setIsUpdateModal(false)}
                title={window.locales.updateObject}
                confirm={{
                    label: window.locales.update,
                    onConfirm: updateObject,
                }}
            >
                Do you want to update the {objectStore.objectData.metadata.name} object? All your changes will be
                discarded.
            </Modal>
            <div className={localeStyle.objectContainer}>
                <div className={localeStyle.objectContainer__header}>
                    <EnvIconContainer action={() => setIsUpdateModal(true)}>
                        <img src={iconRefresh} alt="img" />
                    </EnvIconContainer>
                    <If condition={canDelete}>
                        <EnvIconContainer action={() => setIsDeleteModal(true)}>
                            <img src={iconDelete} alt="img" />
                        </EnvIconContainer>
                    </If>
                </div>
                <KindMetadata />
                <KindHeader activeTab={tab} changeTab={setTab} />
                <div className={localeStyle.objectContainer__content}>
                    {tab === ObjectTab.UI && <KindForm schema={schema} />}
                    {tab === ObjectTab.JSON && <KindConfig schema={schema} />}
                    <div className={buttonStyle.groupButtonContainer}>
                        <button
                            className={buttonStyle.buttonCancel}
                            onClick={() => navigate(`/${NAMESPACES_PAGE}/${namespace}/${kind}`)}
                        >
                            {window.locales.cancel}
                        </button>
                        <div className={buttonStyle.singleButtonContainer}>
                            <button
                                disabled={!canCreateAndEdit || isButtonDisabled}
                                className={buttonStyle.buttonSubmit}
                                onClick={saveButton}
                            >
                                {window.locales.save}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Kind;
