import { IMetadata } from "@screens/Objects/Objects.types";
import { TableObjectProps } from "@components/Table/types";

type Operator =
    | "exact"
    | "in"
    | "not"
    | "regex"
    | "prefix"
    | "int_lt"
    | "int_gt"
    | "int_le"
    | "int_ge"
    | "int_eq"
    | "int_ne"
    | "eq"
    | "semver"
    | "sample"
    | "$and"
    | "$or";

// Selector Type
interface Selector {
    type: "object"; // Fixed value as per schema
    operator: Operator; // Operator defined in the schema
    value: string | string[] | null; // Value can be string, array (for 'in'), or null
}

// ClientConfigOverride Type
interface ClientConfigOverride {
    user: string; // String input for the user field
    device: string; // String input for the device field
    ip_selector: Record<string, unknown>; // The IP Selector object with operator and value
    server_selector: Record<string, unknown>; // The Server Selector object with operator and value
}

type OverridesTableType = {
    name: string;
    age: TableObjectProps;
    buttons?: TableObjectProps;
    event?: () => void;
};

const initialOverride: ClientConfigOverride = {
    user: "",
    device: "",
    ip_selector: {},
    server_selector: {},
};

type OverrideObjectType = {
    metadata: IMetadata;
    data: ClientConfigOverride;
};

export type { ClientConfigOverride, Selector, Operator, OverrideObjectType, OverridesTableType };
export { initialOverride };
