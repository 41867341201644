import { ResultResponse } from "@screens/Objects/Object/Object.constants";
import { successInfo } from "@state/success";
import { errorInfo } from "@state/error";
import { IObjectResponse } from "@services/Objects";

export const checkObjectResponse = (data: IObjectResponse, successAction: () => void) => {
    if (data.result === ResultResponse.PUT_RESULT_OK) {
        successInfo.setSuccessInfo();
        successAction();
    } else if (data.result === ResultResponse.PUT_RESULT_CONFLICT) {
        errorInfo.setErrorInfo({
            title: data.result,
            description: window.locales.errorPutResultConflict,
        });
    } else if (data.result === ResultResponse.DELETE_RESULT_OK) {
        successInfo.setSuccessInfo();
        successAction();
    } else if (data.result === ResultResponse.PUT_RESULT_ALREADY_EXISTS) {
        errorInfo.setErrorInfo({
            title: data.result,
            description: window.locales.errorPutResultAlreadyExists,
        });
    } else if (data.result === ResultResponse.GET_RESULT_NOT_FOUND) {
        errorInfo.setErrorInfo({
            title: data.result,
            description: window.locales.errorGetResultNotFound,
        });
    }
};
