import type {} from "@mui/x-data-grid/themeAugmentation";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#007bff", // Change the primary color for form inputs
        },
        secondary: {
            main: "#ff6f61", // Change the secondary color for form inputs
        },
        text: {
            primary: "#FFF", // Change the text color for form inputs
        },
        background: {
            default: "#f0f0f0", // Change the background color for form inputs
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& fieldset": {
                        borderWidth: 0, // Remove the outline border
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: "#303340",
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#FFFFFFCC",
                    fontWeight: 600,
                    position: "relative",
                },
            },
        },
    },
});

export { theme };
