import React, { ChangeEvent, ReactElement, useMemo } from "react";
import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";

import getDateUTCString from "@common/methods/getDateUTCString";
import objectStore from "@state/object";
import * as localStyle from "@screens/Objects/Object/ObjectMetadata/ObjectMetadata.style.scss";
import { CustomInput } from "@screens/Landing/Authorization/Input";

const regexKey = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;
const ConfigMetadata = observer((): ReactElement => {
    const data = useMemo(() => objectStore.objectData.metadata, [objectStore.objectData.metadata]);

    return (
        <Formik
            initialValues={{
                name: data.name,
                create_time: getDateUTCString(data.createTime),
                update_time: getDateUTCString(data.updateTime),
                new_label: "",
                ...data.labels,
            }}
            enableReinitialize
            validate={(values) => {
                const errors: Record<string, string> = {};
                if (values.new_label && !regexKey.test(values.new_label)) {
                    errors.new_label = window.locales.incorrectValue;
                }

                return errors;
            }}
            onSubmit={async () => {}}
        >
            {({ handleSubmit, handleChange }) => (
                <form onSubmit={handleSubmit} className={localStyle.metadataContainer}>
                    <Field
                        component={CustomInput}
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            objectStore.updateMetadata({ name: event.target.value });
                            handleChange(event);
                        }}
                        disabled={objectStore.objectData.metadata.name}
                    />
                    <Field component={CustomInput} disabled type="text" name="create_time" placeholder="Create time" />
                    <Field component={CustomInput} disabled type="text" name="update_time" placeholder="Update time" />
                </form>
            )}
        </Formik>
    );
});

export default ConfigMetadata;
