import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    listOfUsers: [],
    selectedUser: {
        name: "",
        id: "",
    },
};

const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        getUsersData(state: { listOfUsers: any[] }, action: PayloadAction<string>) {},
        setUsers(state, action) {
            state.listOfUsers = action.payload;
        },
        loadMoreUsers(state: { listOfUsers: any[] }, action: PayloadAction<any>) {},
        getUser(state, action) {},
        setUser(state, action) {
            state.selectedUser = action.payload;
        },
    },
});

export const { getUsersData, setUsers, getUser, setUser, loadMoreUsers } = usersSlice.actions;

export { initialState as initUsers };

export default usersSlice.reducer;
