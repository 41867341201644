import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import React, { useCallback, useEffect, useState } from "react";
import QRCode from "qrcode";

import { multiFactor, TotpMultiFactorGenerator, TotpSecret } from "firebase/auth";
import { errorInfo } from "@state/error";
import * as buttonStyle from "@components/Button/style.scss";
import { InputCode } from "@screens/Landing/Authorization/InputCode";
import { IErrorResponse } from "@interfaces";

function QRCodeMethod({ backToSignInButton, email }: { backToSignInButton: () => void; email: string }) {
    const [isFirstStep, setIsFirstStep] = useState(true);
    const [isError, setIsError] = useState(false);
    const [qrCodeUrl, setQrCodeUrl] = useState("");
    const [secret, setSecret] = useState<TotpSecret>();
    const getTotpSecret = useCallback(async () => {
        try {
            const currentUser = window.fb.default.auth().currentUser;
            const multiFactorSession = await multiFactor(currentUser).getSession();
            const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
            await getQRCode(totpSecret);

            setSecret(totpSecret);
        } catch (e: unknown) {
            setIsError(true);
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    }, []);

    const getQRCode = async (totpSecret: TotpSecret) => {
        const currentUser = window.fb.default.auth().currentUser;
        const totpUri = totpSecret.generateQrCodeUrl(currentUser.email, "Your App's Name");
        setQrCodeUrl(qrCodeUrl);

        QRCode.toDataURL(totpUri, { width: 256 }, (err, url) => {
            if (err) {
                console.error("Error generating QR code", err);
                return;
            }
            setQrCodeUrl(url);
        });
    };

    const verifyCode = async (code: string) => {
        if (!secret) return;
        const currentUser = window.fb.default.auth().currentUser;
        try {
            const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(secret, code);
            await multiFactor(currentUser).enroll(multiFactorAssertion, "TOTP");
            location.reload();
        } catch (e: unknown) {
            setIsError(true);
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    useEffect(() => {
        getTotpSecret().catch((e) => {
            backToSignInButton();
            errorInfo.setErrorInfo({
                description: e.message,
            });
        });
    }, []);

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Verification</div>
                    <div className={authorizationStyle.text}>
                        {isFirstStep
                            ? `Scan this QR Code or use this secret Key: ${secret?.secretKey}`
                            : `Enter 6-digit code for ${email}`}
                    </div>
                </div>
                <div className={authorizationStyle.formContainer}>
                    <div className={authorizationStyle.qrCodeContainer}>
                        {isFirstStep ? (
                            qrCodeUrl ? (
                                <img src={qrCodeUrl} alt="QR Code" />
                            ) : (
                                <p>Generating QR Code...</p>
                            )
                        ) : (
                            <InputCode isError={isError} submit={verifyCode} />
                        )}
                    </div>
                    {isFirstStep && (
                        <div className={authorizationStyle.buttonContainer}>
                            <button
                                type="button"
                                className={buttonStyle.buttonSubmit}
                                onClick={() => setIsFirstStep(false)}
                            >
                                Verify
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default QRCodeMethod;
