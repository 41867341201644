import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { validate as isValidUUID } from "uuid";

import * as localStyle from "@screens/Auth/Auth.styled.scss";
import * as style from "@screens/Objects/Objects.style.scss";
import { Search } from "@components/Search/Search";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus, IconSearch } from "@common/icons";
import authUserService from "@services/Auth/User/Auth.User.service";
import { NEW_PAGE } from "@common/constant/urls";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import Table from "@components/Table";
import { accumulateUsers, headingUsers } from "@screens/Auth/AuthUser/AuthUser.utils";
import { AuthUserDataType, AuthUsersType } from "@services/Auth/User/Auth.User.types";
import { Modal } from "@components/Modal";
import { If } from "@components/If";

function AuthUser() {
    const [searchValue, setSearchValue] = useState<string>("");
    const navigate = useNavigate();
    const [selectedUser, setSelectedUser] = useState<AuthUserDataType | null>(null);
    const [dataUsers, setDataUsers] = useState<AuthUsersType>({
        users: [],
        cursor: "",
    });
    const { project: namespace = "" } = useParams();
    authUserService.setProject(namespace);
    const searchCallback = (value: string) => {
        setSearchValue(value);
    };

    useEffect(() => {
        getUsers().then();
    }, []);

    const getUsers = async () => {
        const { data } = await authUserService.list({
            limit: 100,
        });
        if (data) {
            setDataUsers(data);
        }
    };

    const getUser = async () => {
        try {
            if (isValidUUID(searchValue)) {
                const { data } = await authUserService.get({ user_id: searchValue });
                data.id && navigate(data.id);
            } else {
                const { data } = await authUserService.get({ alias: searchValue });
                data.id && navigate(data.id);
            }
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    const tableData = useMemo(() => accumulateUsers(dataUsers.users, navigate, setSelectedUser), [navigate, dataUsers]);

    const deleteUser = async () => {
        setSelectedUser(null);
        if (selectedUser && selectedUser.id) {
            await authUserService.delete({ user_id: selectedUser.id });
            getUsers().then();
        }
    };

    const loadMore = async () => {
        const { data } = await authUserService.list({
            cursor: dataUsers.cursor,
            limit: 100,
        });

        if (data) {
            setDataUsers((prevState) => ({
                users: [...prevState.users, ...dataUsers.users],
                cursor: data.cursor,
            }));
        }
    };

    return (
        <>
            <Modal
                isNegative
                isOpen={!!selectedUser}
                onClose={() => setSelectedUser(null)}
                title={window.locales.deletePoolGroup}
                confirm={{
                    label: window.locales.delete,
                    onConfirm: deleteUser,
                }}
            >
                Do you want to delete {selectedUser?.username}?
            </Modal>
            <div className={localStyle.authUserPageContainer}>
                <Table
                    tableData={tableData}
                    headings={headingUsers}
                    title="projects"
                    emptyMessage={window.locales.noUser}
                >
                    <div className={style.tableHeaderContainer}>
                        <div className={style.searchContainer}>
                            <Search callback={searchCallback} enterEvent={getUser} />
                        </div>
                        <button className={buttonStyle.buttonAdd} onClick={getUser}>
                            <IconSearch theme="blue flex" /> {window.locales.search}
                        </button>
                        <button className={buttonStyle.buttonAdd} onClick={() => navigate(NEW_PAGE)}>
                            <IconPlus theme="blue" />
                            {window.locales.add}
                        </button>
                    </div>
                </Table>
                <If condition={!!dataUsers.cursor}>
                    <div className={localStyle.loadMoreWrapper}>
                        <button className={buttonStyle.buttonAdd} type="submit" onClick={loadMore}>
                            <IconPlus theme="blue" />
                            {window.locales.loadMore}
                        </button>
                    </div>
                </If>
            </div>
        </>
    );
}

export default AuthUser;
