import React from "react";

import { Data } from "@components/Table/types";
import { IPoolGroup } from "@screens/Network/Pool/Servers/types";
import { Checkbox } from "@common/form";
import { TargetIcon } from "@components/TargetIcon";
import * as table from "@components/Table/style.scss";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";

const selectorElement = (
    value: { in?: string[]; not?: { in: string[] } } | string | string[],
    keyName: string,
    index: number,
) => {
    const nameSelector = keyName;
    if (Array.isArray(value)) {
        return (
            <div key={index} className={table.tableTagContainer}>
                <div className={table.tableTagName}>{`${nameSelector}: `}</div>
                {value.map((text, key) => (
                    <div className={table.tableTagCountries} key={key}>
                        {text}
                    </div>
                ))}
            </div>
        );
    }
    if (typeof value === "object") {
        return (
            <div key={index} className={table.tableTagContainer}>
                <div className={table.tableTagName}>{`${nameSelector}: `}</div>
                {value.in?.map((text, key) => (
                    <div className={table.tableTagCountries} key={key}>
                        in: {text}
                    </div>
                ))}
                {value.not?.in?.map((text, key) => (
                    <div className={table.tableTagCountries} key={key}>
                        not in: {text}
                    </div>
                ))}
            </div>
        );
    }
    return (
        <div key={index} className={table.tableTagContainer}>
            <div className={table.tableTagName}>{`${nameSelector}: `} </div>
            <div className={table.tableTagCountries}> {value} </div>
        </div>
    );
};

const getSelectorElement = (values: { [key: string]: any } | undefined) => {
    if (!values) return <div />;
    const serverSelector = Object.entries(values).map((item) => ({
        name: item[0],
        value: item[1],
    }));
    return (
        <div className={table.tableTagContainer}>
            {serverSelector.map((item, index) => selectorElement(item.value, item.name, index))}
        </div>
    );
};

const actionElement = (
    editGroup: (group: IPoolGroup) => void,
    showDeleteModal: (group: IPoolGroup) => void,
    group: IPoolGroup,
    index: number,
) => (
    <div className={table.buttonsColumn} key={index}>
        <TargetIcon onClick={() => editGroup(group)}>
            <img src={iconPen} alt="img" />
        </TargetIcon>
        <TargetIcon onClick={() => showDeleteModal(group)}>
            <img src={iconTrash} alt="img" />
        </TargetIcon>
    </div>
);

const accumulateOptimalLocation = (
    groups: IPoolGroup[] = [],
    privateGroups: string[] = [],
    changeGroupStatus: (request_selector: any) => void,
    canWrite: boolean,
    isPrivate: boolean,
    editGroup: (group: IPoolGroup) => void,
    showDeleteModal: (group: IPoolGroup) => void,
): Data[] =>
    groups.map((group: IPoolGroup, index) => {
        const { name = "", priority = 0, request_selector = {}, server_selector = {}, description = "" } = group;

        const requestSelector = {
            ...(request_selector.user_country && { user_country: request_selector.user_country }),
            ...(request_selector.user_state && { user_state: request_selector.user_state }),
        };

        const serverSelector = {
            ...(server_selector.server_country && { server_country: server_selector.server_country }),
            ...(server_selector.server_state && { server_state: server_selector.server_state }),
        };

        return {
            checkBox: {
                component:
                    canWrite && isPrivate ? (
                        <Checkbox
                            key={index}
                            theme="blue small"
                            status={privateGroups.indexOf(request_selector.private_group) !== -1 ? "on" : "off"}
                            changed={() => changeGroupStatus(request_selector)}
                        />
                    ) : (
                        <div key={index} />
                    ),
            },
            rule_name: name,
            priority,
            request_selector: {
                component: getSelectorElement(requestSelector) || <div />,
            },
            server_selector: {
                component: getSelectorElement(serverSelector) || <div />,
            },
            description,
            actions: {
                component: canWrite ? actionElement(editGroup, showDeleteModal, group, index) : <div />,
            },
        };
    });

const headings = [
    {
        isSort: false,
        heading: "",
    },
    {
        isSort: true,
        heading: "Rule name",
    },
    {
        isSort: true,
        heading: window.locales.priority,
    },
    {
        isSort: false,
        heading: window.locales.request_selector,
    },
    {
        isSort: false,
        heading: window.locales.server_selector,
    },
    {
        isSort: false,
        heading: window.locales.server_selector.description,
    },
    {
        isSort: false,
        heading: "",
    },
];

export { accumulateOptimalLocation, headings, getSelectorElement, selectorElement };
