import React, { ReactElement } from "react";
import { Field, FieldArray, Formik } from "formik";

import { Modal } from "@components/Modal";
import { CustomSelect, TextField } from "@components/FormikFields";
import { NameContainer } from "@components/FormikFields/components";
import * as localStyle from "./RoleModal.styled.scss";
import * as poolStyle from "@screens/Network/Pool/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconTrash from "@common/img/svg/trash-icon.svg";
import * as buttonStyle from "@components/Button/style.scss";
import { IconPlus } from "@common/icons";
import { AccessRoleType } from "@screens/Access/Roles/Roles";
import { ModalType } from "@components/Modal/types";

const permissionsOptions = ["get", "put", "delete", "list", "watch", "all"].map((value) => ({ label: value, value }));

const defaultPermission = {
    subject: "",
    list: [],
};

function RoleModal({ title, isOpen, closeModal, action, item }: ModalType<AccessRoleType>): ReactElement {
    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            title={title}
            confirm={{
                label: window.locales.confirm,
                onConfirm: () => {},
            }}
            /* ! need to specify a name for the submitted Form and a type for the Button (submit) */
            typeButton="submit"
            nameForm="tokenForm"
            disabled={false}
        >
            <Formik
                initialValues={{
                    allow_paths: item.allow_paths ? item.allow_paths.join(", ") : "",
                    permissions: item.allow
                        ? Object.entries(item.allow).map(([subject, list]) => ({ subject, list }))
                        : [],
                    name: item.name,
                }}
                validate={(values) => {
                    const errors: Record<string, string> = {};
                    const { name, permissions } = values;
                    if (!name) errors.name = window.locales.invalidFieldEmpty;
                    const index = permissions?.findIndex((permission) => !permission.subject);
                    if (index !== -1) errors[`permissions.${index}.subject`] = window.locales.emptyField;
                    return errors;
                }}
                onSubmit={({ name, allow_paths, permissions }) => {
                    action({
                        name,
                        allow_paths: allow_paths ? allow_paths.replaceAll(" ", "").split(",") : undefined,
                        allow: permissions.reduce(
                            (accumulator, { subject, list }) => ({ ...accumulator, [subject]: list }),
                            {},
                        ),
                    });
                    closeModal();
                }}
            >
                {({ handleSubmit, values, touched }) => (
                    /* ! add id to match nameForm from the Modal props  */
                    <form id="tokenForm" onSubmit={handleSubmit}>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type name"
                                type="text"
                                name="name"
                                disabled={item.name}
                            />
                        </section>
                        <section>
                            <Field
                                component={TextField}
                                placeholder="Type allow paths"
                                type="text"
                                name="allow_paths"
                            />
                        </section>
                        <section>
                            <NameContainer $isError={false}>{window.locales.allow}</NameContainer>
                            <FieldArray
                                name="permissions"
                                render={(arrayHelpers) => (
                                    <>
                                        <div className={localStyle.permissionContainer}>
                                            {values.permissions &&
                                                values.permissions.length > 0 &&
                                                values.permissions.map((permission, i) => (
                                                    <div key={i} className={poolStyle.permissionContainer}>
                                                        <Field
                                                            extraTouched={
                                                                touched.permissions?.length && !!touched.permissions[i]
                                                            }
                                                            component={TextField}
                                                            placeholder="Project id"
                                                            type="text"
                                                            name={`permissions.${i}.subject`}
                                                        />
                                                        <Field
                                                            isMulti
                                                            component={CustomSelect}
                                                            options={permissionsOptions}
                                                            name={`permissions.${i}.list`}
                                                            placeholder="Select permission"
                                                        />
                                                        <TargetIcon onClick={() => arrayHelpers.remove(i)}>
                                                            <img src={iconTrash} alt="img" />
                                                        </TargetIcon>
                                                    </div>
                                                ))}
                                        </div>
                                        <section className={poolStyle.extraButtons}>
                                            <button
                                                type="button"
                                                onClick={() => arrayHelpers.push(defaultPermission)}
                                                className={buttonStyle.buttonAdd}
                                            >
                                                <IconPlus theme="blue" />
                                                {window.locales.addPermission}
                                            </button>
                                        </section>
                                    </>
                                )}
                            />
                        </section>
                    </form>
                )}
            </Formik>
        </Modal>
    );
}

export default RoleModal;
