import { NavigateFunction } from "react-router/dist/lib/hooks";
import React from "react";

import { ObjectType, IObjectTable, SchemaTableType, KindTableType } from "@screens/Objects/Objects.types";
import * as table from "@components/Table/style.scss";
import { TargetIcon } from "@components/TargetIcon";
import iconPen from "@common/img/svg/pen-icon.svg";
import iconTrash from "@common/img/svg/trash-icon.svg";
import Base64toJSON from "@common/methods/base64toJSON";
import { PermissionEnum } from "@screens/Objects/Object/Object.constants";
import { If } from "@components/If";
import { GLOBAL_PAGE } from "@common/constant/urls";
import { getAge } from "@common/getAge";

const accumulateNamespaces = (objects: ObjectType[], navigate: NavigateFunction): SchemaTableType[] =>
    objects.map((object: ObjectType) => {
        const {
            metadata: { name },
            data,
        } = object;

        const dataString = JSON.stringify(Base64toJSON(data));
        const row: SchemaTableType = {
            name,
            data: {
                tooltip: {
                    title: dataString,
                    content: dataString,
                },
            },
            event: () => navigate(decodeURIComponent(name)),
        };

        return row;
    });

const accumulateKinds = (objects: ObjectType[], navigate: NavigateFunction): KindTableType[] =>
    objects.map((object: ObjectType) => {
        const {
            metadata: { name },
        } = object;
        const row: KindTableType = {
            name,
            event: () => navigate(decodeURIComponent(name)),
        };

        return row;
    });

const accumulateObjects = (
    objects: ObjectType[],
    navigate: (item: ObjectType) => void,
    onDelete: (object: ObjectType) => void,
    permissions: string[],
): IObjectTable[] =>
    objects.map((object: ObjectType) => {
        const {
            metadata: { name, author, updateTime = "" },
        } = object;

        const userCanDelete = permissions.includes(PermissionEnum.DELETE);
        const userCanRead = permissions.includes(PermissionEnum.GET);

        const row: IObjectTable = {
            name,
            author,
            age: {
                sortColumn: Number(updateTime),
                component: getAge(updateTime),
            },
            buttons: {
                component: (
                    <div className={table.buttonsColumn}>
                        <If condition={userCanRead}>
                            <TargetIcon onClick={() => navigate(object)}>
                                <img src={iconPen} alt="img" />
                            </TargetIcon>
                        </If>
                        <If condition={userCanDelete}>
                            <TargetIcon onClick={() => onDelete(object)}>
                                <img src={iconTrash} alt="img" />
                            </TargetIcon>
                        </If>
                    </div>
                ),
            },
        };

        return row;
    });

const namespaceHeader = [
    {
        isSort: true,
        heading: window.locales.namespace,
    },
    {
        isSort: false,
        heading: window.locales.data,
    },
];

const kindHeader = [
    {
        isSort: true,
        heading: window.locales.kind,
    },
];

const objectHeader = [
    {
        isSort: true,
        heading: window.locales.name,
    },
    {
        isSort: true,
        heading: window.locales.author,
    },
    {
        isSort: true,
        heading: window.locales.age,
    },
];

const initObject: ObjectType = {
    metadata: {
        labels: {},
        namespace: "",
        revision: "",
        hash: "",
        kind: "",
        name: "",
        author: "",
    },
    data: "",
};

const generalNamespace: ObjectType = {
    metadata: {
        labels: {},
        name: GLOBAL_PAGE,
        kind: "kind",
        namespace: "",
        revision: "",
        updateTime: "",
        createTime: "",
        hash: "",
        author: "",
    },
};

export {
    objectHeader,
    generalNamespace,
    accumulateKinds,
    accumulateObjects,
    namespaceHeader,
    accumulateNamespaces,
    kindHeader,
    initObject,
};
