import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { InputCode } from "@screens/Landing/Authorization/InputCode";
import { resetActiveProject } from "@state/activeProject";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import { Timer } from "@components/Timer";
import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";

import * as style from "./style.scss";
import { TotpMultiFactorGenerator, PhoneMultiFactorGenerator } from "firebase/auth";

const CodeForm = ({ resolver, email }: { resolver: any; email: string }) => {
    const dispatch = useDispatch();
    const captchaRef = useRef(null);
    const verificationId = useRef("");
    const [isError, setIsError] = useState(false);
    const [multiFactorHint = { phoneNumber: "", factorId: "", uid: "" }] = useMemo(
        () => resolver?.hints ?? [],
        [resolver?.hints],
    );
    const { phoneNumber, factorId, uid } = multiFactorHint;
    const recaptchaVerifier = useRef(null);
    const [isResend, setIsResend] = useState(false);

    const useTOTPMethod = async (code: string) => {
        const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(uid, code);
        if (!resolver) return null;
        await resolver.resolveSignIn(multiFactorAssertion);
    };

    const usePhoneMethod = async (code: string) => {
        const cred = window.fb.default.auth.PhoneAuthProvider.credential(verificationId.current, code);
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
        if (!resolver) return null;
        await resolver.resolveSignIn(multiFactorAssertion);
    };

    const verifyCode = async (code: string) => {
        try {
            if (TotpMultiFactorGenerator.FACTOR_ID === factorId) {
                await useTOTPMethod(code);
            } else if (PhoneMultiFactorGenerator.FACTOR_ID === factorId) {
                await usePhoneMethod(code);
            } else {
                return;
            }

            dispatch(resetActiveProject());
        } catch (e: unknown) {
            setIsError(true);
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    const resendCode = () => {
        setIsResend(false);
        const phoneInfoOptions = {
            multiFactorHint,
            session: resolver?.session,
        };

        const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier.current).then((value: string) => {
            verificationId.current = value;
        });
    };

    useEffect(() => {
        if (!resolver || TotpMultiFactorGenerator.FACTOR_ID === factorId) {
            return;
        }

        recaptchaVerifier.current = new window.fb.default.auth.RecaptchaVerifier(captchaRef.current, {
            size: "invisible",
        });
        const phoneInfoOptions = {
            multiFactorHint,
            session: resolver?.session,
        };

        const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
        phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier.current)
            .then((value: string) => {
                verificationId.current = value;
            })
            .catch(() => {
                // @ts-ignore
                window.grecaptcha.reset(captchaRef.current);
            });
    }, [multiFactorHint, resolver]);

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Verification</div>
                    <div className={authorizationStyle.text}>
                        {PhoneMultiFactorGenerator.FACTOR_ID === factorId ? (
                            <>
                                Enter 6-digit code, we’ve sent you on <span>{phoneNumber}</span>
                            </>
                        ) : (
                            <>
                                Enter 6-digit code for <span>{email}</span>
                            </>
                        )}
                    </div>
                </div>
                <div className={authorizationStyle.formContainer}>
                    <div className={authorizationStyle.fieldContainer}>
                        <InputCode
                            isError={isError}
                            submit={async (code: string) => {
                                setIsError(false);
                                await verifyCode(code);
                            }}
                        />
                    </div>
                    {PhoneMultiFactorGenerator.FACTOR_ID === factorId && (
                        <div className={authorizationStyle.infoContainer}>
                            <span>Can’t find the code?</span>
                            <div className={style.buttonResend}>
                                {isResend ? (
                                    <button type="button" onClick={resendCode}>
                                        Resend
                                    </button>
                                ) : (
                                    <Timer
                                        takeOffTimer={() => {
                                            setIsResend(true);
                                            setIsError(false);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div ref={captchaRef} />
            </div>
        </div>
    );
};

export { CodeForm };
